export const formatCPF = (value: string) => {
  if (!value) return "";
  let formattedValue = value.replace(/\D/g, ""); // Remove caracteres não numéricos
  formattedValue = formattedValue.slice(0, 11); // Limita o tamanho da string

  if (formattedValue.length > 9) {
    // Insere pontos e hífen nas posições corretas
    formattedValue = `${formattedValue.slice(0, 3)}.${formattedValue.slice(
      3,
      6
    )}.${formattedValue.slice(6, 9)}-${formattedValue.slice(9)}`;
  } else if (formattedValue.length > 6) {
    // Insere pontos nas posições corretas
    formattedValue = `${formattedValue.slice(0, 3)}.${formattedValue.slice(
      3,
      6
    )}.${formattedValue.slice(6)}`;
  } else if (formattedValue.length > 3) {
    formattedValue = `${formattedValue.slice(0, 3)}.${formattedValue.slice(3)}`;
  }

  return formattedValue;
};

export const validateCPF = (cpf: string) => {
  cpf = cpf.replace(/\D/g, ""); // Remove caracteres não numéricos

  // Verifica se o CPF tem 11 dígitos e não é uma sequência repetida
  if (cpf.length !== 11 || /^(.)\1+$/.test(cpf)) {
    return false;
  }

  // Calcula o dígito verificador do CPF
  const digit1 = parseInt(cpf.charAt(9));
  const digit2 = parseInt(cpf.charAt(10));
  let sum = 0;
  let weight = 10;

  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * weight;
    weight--;
  }

  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

  if (result !== digit1) {
    return false;
  }

  sum = 0;
  weight = 11;

  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * weight;
    weight--;
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

  return result === digit2;
};

export const formatDateTime = (oldValue: string | undefined, value: string) => {
  if ((oldValue?.length ?? 0) > value.length) {
    return value;
  }
  let formattedValue = value.replace(/\D/g, ""); // Remove caracteres não numéricos
  formattedValue = formattedValue.slice(0, 12); // Limita o tamanho da string

  if (formattedValue.length > 8) {
    // Insere barras e dois pontos nas posições corretas
    formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(
      2,
      4
    )}/${formattedValue.slice(4, 8)} ${formattedValue.slice(
      8,
      10
    )}:${formattedValue.slice(10)}`;
  } else if (formattedValue.length > 4) {
    // Insere barras nas posições corretas
    formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(
      2,
      4
    )}/${formattedValue.slice(4, 8)} ${formattedValue.slice(8)}`;
  } else if (formattedValue.length > 2) {
    formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(2)}`;
  }

  return formattedValue;
};
