import { Dispatch, SetStateAction, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faClose } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";
import { Tag as TagType } from "../models/Filter";
import { Form, useForm } from "react-hook-form";

const TagFilter = ({
  tags,
  setTags,
  type,
  label,
}: {
  tags: TagType[];
  setTags: Dispatch<SetStateAction<TagType[]>>;
  type: string;
  label: string;
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [selectedValue, setSelectedValue] = useState<string>("");
  const { register, handleSubmit, formState, getValues, reset } = useForm();
  const [inputValue, setInputValue] = useState("");

  return (
    <form onSubmit={handleSubmit((d) => {})}>
      <Heading size="sm" mb="8px">
        {label}
      </Heading>
      <Flex>
        <Input
          backgroundColor={"white"}
          w={{ base: "100%", md: "16vw" }}
          name="value"
          value={selectedValue}
          onChange={(e) => setSelectedValue(e.target.value)}
          onKeyUp={(e) => {
            if (
              e.key === "Enter" &&
              selectedValue.length > 0 &&
              !tags.some((t) => t.label === type && t.value === selectedValue)
            ) {
              setTags((tags: TagType[]) => [
                ...tags,
                {
                  type,
                  label,
                  value: selectedValue,
                },
              ]);
              setSelectedValue("");
            }
          }}
        />
      </Flex>
      <Flex
        flexWrap="wrap"
        gap="12px"
        maxHeight="130px"
        overflowY={
          `${tags.map((t) => t.label).join("")}${tags
            .map((t) => t.value)
            .join("")}}`.length > 60
            ? "scroll"
            : "hidden"
        }
        marginY="24px"
      >
        {tags
          .filter((t) => t.type === type)
          .map((tag) => (
            <Flex
              backgroundColor="var(--icon-color)"
              borderColor="#5A96E3"
              borderRadius="3px"
              borderWidth="1px"
              height="32px"
              padding="4px 8px"
              color="white"
              alignItems="center"
              gap="8px"
              cursor="pointer"
              onClick={() =>
                setTags((tags: TagType[]) => {
                  const newTags = tags.map((t) => t);
                  newTags.splice(
                    tags.findIndex(
                      (t) => t.label === tag.label && t.value === tag.value
                    ),
                    1
                  );
                  return newTags;
                })
              }
            >
              <Text fontWeight="semibold">{tag.value}</Text>
              <FontAwesomeIcon icon={faClose} color="white" />
            </Flex>
          ))}
      </Flex>
    </form>
  );
};

export default TagFilter;
