import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import BackButton from "../../components/BackButton";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { UserViewModel } from "../../models/User";
import { getUser, updateUser } from "../../services/userService";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { formatCPF } from "../../services/utils";
import { FaAngleRight } from "react-icons/fa";

const Account = () => {
  const toast = useToast();
  const [user, setUser] = useState<UserViewModel>();
  const [loading, setLoading] = useState(false);
  const [cpf, setCpf] = useState<string>(user?.document ?? "");
  const [receiveNotification, setReceiveNotification] = useState<boolean>(
    user?.receiveNotification ?? false
  );
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const navigate = useNavigate();
  const auth = useAuthUser<any>();
  const { register, handleSubmit } = useForm();
  const save = async (data: any) => {
    setLoading(true);
    console.log("asd-id", user);
    const response = await updateUser({
      id: user?.id,
      document: data.document ?? user?.document,
      email: data.email ?? user?.email,
      firstname: data.firstname ?? user?.firstname,
      lastname: data.lastname ?? user?.lastname,
      receiveNotification,
    });
    if (response) {
      toast({
        title: "Usuário editado.",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
    setLoading(false);
  };
  const get = async () => {
    const response = await getUser(auth?.userId);
    if (response) {
      setUser(response);
      setCpf(formatCPF(response.document));
      setReceiveNotification(response.receiveNotification);
    }
  };
  useEffect(() => {
    if (auth) get();
  }, [auth]);
  return (
    <Box>
      <Header />
      <Flex
        height="100%"
        overflow="hidden"
        alignItems="center"
        flexDirection="column"
        backgroundColor="var(--main-bg-color)"
        paddingBottom="32px"
      >
        <Heading
          my="48px"
          fontWeight="normal"
          textAlign="left"
          w={{ base: "90%", md: "50%" }}
        >
          Configurações da conta
        </Heading>
        <Heading
          mb="24px"
          fontWeight="normal"
          textAlign="left"
          w={{ base: "90%", md: "50%" }}
          size="lg"
        >
          Informações
        </Heading>
        <form
          onSubmit={handleSubmit(save)}
          style={{ width: isMobile ? "95%" : "50%" }}
        >
          <Flex
            flexDirection="column"
            padding="64px"
            gap="32px"
            marginTop="32px"
            background="white"
            width="100%"
            justifyContent="center"
            alignItems="center"
            borderRadius="8px"
          >
            <Flex
              justifyContent="space-between"
              flexDirection={"column"}
              w="100%"
            >
              <Heading size="md" mb="8px">
                Primeiro nome
              </Heading>
              <Input
                defaultValue={user?.firstname}
                {...register("firstname")}
              />
            </Flex>
            <Flex
              justifyContent="space-between"
              flexDirection={"column"}
              w="100%"
            >
              <Heading size="md" mb="8px">
                Sobrenome
              </Heading>
              <Input defaultValue={user?.lastname} {...register("lastname")} />
            </Flex>
            <Flex
              justifyContent="space-between"
              flexDirection={"column"}
              w="100%"
            >
              <Heading size="md" mb="8px">
                Email
              </Heading>
              <Input defaultValue={user?.email} {...register("email")} />
            </Flex>
            <Flex
              justifyContent="space-between"
              flexDirection={"column"}
              w="100%"
            >
              <Heading size="md" mb="8px">
                Documento
              </Heading>
              <Input
                value={cpf}
                defaultValue={user?.document}
                {...register("document", {
                  onChange: (e) => setCpf(formatCPF(e.target.value)),
                })}
              />
            </Flex>
            <Checkbox
              w="100%"
              mt="32px"
              isChecked={receiveNotification}
              onChange={(e) => setReceiveNotification(e.target.checked)}
            >
              Receber notificações de containers no e-mail.
            </Checkbox>
          </Flex>
          <Heading
            mt="48px"
            mb="24px"
            fontWeight="normal"
            textAlign="left"
            w={{ base: "90%", md: "50%" }}
            size="lg"
          >
            Senha e segurança
          </Heading>
          <Flex
            gap="32px"
            marginTop="32px"
            p="24px"
            background="white"
            width="100%"
            alignItems="center"
            borderRadius="8px"
          >
            <Flex
              w="100%"
              alignItems="center"
              justifyContent="space-between"
              cursor="pointer"
              onClick={() => navigate("/changePassword")}
            >
              <Heading fontWeight="normal" size="md" mb="8px">
                Alterar senha
              </Heading>
              <FaAngleRight />
            </Flex>
          </Flex>
          <Flex
            w="100%"
            justifyContent="flex-end"
            flexDirection="row"
            marginTop="64px"
            gap="16px"
          >
            <Button
              width="120px"
              backgroundColor="transparent"
              color="var(--icon-color)"
              borderColor="var(--icon-color)"
              borderWidth="2px"
              borderRadius="4px"
              isLoading={loading}
              onClick={() => navigate(-1)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              isLoading={loading}
              color="white"
              borderRadius="4px"
              width="120px"
              backgroundColor="var(--icon-color)"
            >
              Salvar
            </Button>
          </Flex>
        </form>
      </Flex>
    </Box>
  );
};
export default Account;
