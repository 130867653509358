import { useEffect, useState } from "react";
import {
  Tr,
  Th,
  Td,
  Box,
  Flex,
  Heading,
  Table,
  TableContainer,
  Thead,
  Tbody,
  Spinner,
} from "@chakra-ui/react";
import { getProcessById } from "../services/processService";
import { ProcessViewModel } from "../models/Process";

const NestedTabledata = ({
  row,
  columns,
  nestedColumns,
  isMobile,
  financial,
}: {
  row: any;
  isMobile: boolean;
  financial: boolean;
  columns: {
    label: string;
    data: any;
    width?: string[] | string | number;
  }[];
  nestedColumns?: {
    label: string;
    data: any;
    width?: string[] | string | number;
  }[];
}) => {
  const [expanded, setExpanded] = useState(false);
  const [data, setData] = useState<ProcessViewModel>(row);
  const getDetails = async () => {
    const response = await getProcessById(row.id);
    if (response) {
      setData(response);
    }
  };
  useEffect(() => {
    if (!financial && expanded) {
      getDetails();
    }
  }, [expanded]);
  return (
    <>
      <Tr key={data.id}>
        {columns.map((column) => (
          <Td textTransform="none" key={column.label}>
            {column.data(data, expanded, setExpanded)}
          </Td>
        ))}
      </Tr>
      {nestedColumns && expanded && (
        <Tr>
          {data.items?.length > 0 ? (
            <Td colSpan={columns.length}>
              <Box backgroundColor="whitesmoke">
                {isMobile ? (
                  <Flex
                    backgroundColor="whitesmoke"
                    flexDirection="column"
                    padding="16px"
                    gap="8px"
                  >
                    {nestedColumns.map((column) => (
                      <>
                        {column.data(data).props?.children && (
                          <Box>
                            <Heading
                              size="lg"
                              key={column.label}
                              width={column.width}
                            >
                              {column.label.length > 0
                                ? `${column.label}:`
                                : ""}
                            </Heading>
                            {column.data(data)}
                          </Box>
                        )}
                      </>
                    ))}
                  </Flex>
                ) : (
                  <TableContainer w="87vw" overflowX="scroll">
                    <Table>
                      <Thead>
                        <Tr>
                          {nestedColumns.map((column) => (
                            <Th
                              backgroundColor="whitesmoke"
                              position={
                                column.label.length === 0 ? "sticky" : undefined
                              }
                              right={column.label.length === 0 ? 0 : undefined}
                              key={column.label}
                              width={column.width}
                            >
                              {column.label}
                            </Th>
                          ))}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {data.items.map((item: any) => (
                          <Tr>
                            {nestedColumns.map((column) => (
                              <Td
                                backgroundColor="whitesmoke"
                                zIndex={1000}
                                position={
                                  column.label.length === 0
                                    ? "sticky"
                                    : undefined
                                }
                                right={
                                  column.label.length === 0 ? 0 : undefined
                                }
                                key={column.label}
                              >
                                {column.data(item)}
                              </Td>
                            ))}
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
            </Td>
          ) : (
            <Spinner />
          )}
        </Tr>
      )}
    </>
  );
};

export default NestedTabledata;
