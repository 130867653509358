export const options = [
  {
    title: "Dashboard",
    link: "/dashboard",
    icon: "pie_chart",
    color: "#29234b",
  },
  {
    title: "Documentos",
    link: "/documents",
    icon: "description",
    position: "center",
    color: "#debe37",
  },
  {
    title: "Processos",
    link: "/processes",
    icon: "inventory_2",
    position: "right",
    color: "blue.500",
  },
  {
    title: "Financeiro",
    link: "/financial",
    icon: "account_balance",
    position: "right",
    color: "white",
  },
];
