import api from "./api";

export const getCompanies = async () => {
  try {
    const response = await api.get("/api/company");
    if (!response.data.success) {
      console.log("error", response.data);
    }
    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const addPermission = async ({
  permissionId,
  companyId,
}: {
  permissionId: string;
  companyId: string;
}) => {
  try {
    const response = await api.put(
      `/api/company/${companyId}/AddPermission`,
      {
        permissionId,
      }
    );
    if (!response.data.success) {
      console.log("error", response.data.notifications);
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
};
export const removePermission = async ({
  permissionId,
  companyId,
}: {
  permissionId: string;
  companyId: string;
}) => {
  try {
    const response = await api.put(
      `/api/company/${companyId}/RemovePermission`,
      {
        permissionId,
      }
    );
    if (!response.data.success) {
      console.log("error", response.data.notifications);
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
};
