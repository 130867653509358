import { Flex, Heading, Table, Tbody, Td, Thead, Tr } from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { ProcessViewModel } from "../../../models/Process";

const FinancialTable = ({
  data,
  onOpenDetail,
  setSelectedItem,
}: {
  data: any[];
  onOpenDetail: () => void;
  setSelectedItem: Dispatch<SetStateAction<ProcessViewModel>>;
}) => {
  const formatterUsd = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "USD",
  });

  const columns = [
    {
      label: "Nº do processo",
      data: (p: any) => (
        <Flex alignItems="center" gap="16px">
          <Heading size="md" fontWeight="normal">
            {p.cvfRef}
          </Heading>
        </Flex>
      ),
    },
    {
      label: "Termo de pagamento",
      width: "300px",
      data: (p: any) => (
        <Flex alignItems="center" gap="16px">
          <Heading size="md" fontWeight="normal">
            {p.condition}
          </Heading>
        </Flex>
      ),
    },
    {
      label: "Total pago",
      data: (p: any) => (
        <Flex alignItems="center" gap="16px">
          <Heading size="md" color="red.600" fontWeight="normal">
            {formatterUsd.format(parseFloat(p.totalPayed))}
          </Heading>
        </Flex>
      ),
    },
    {
      label: "Total a pagar",
      data: (p: any) => (
        <Flex alignItems="center" gap="16px">
          <Heading size="md" color="green.600" fontWeight="normal">
            {formatterUsd.format(parseFloat(p.totalToPay))}
          </Heading>
        </Flex>
      ),
    },
    {
      label: "Total do processo",
      data: (p: any) => (
        <Flex alignItems="center" gap="16px">
          <Heading size="md" fontWeight="normal">
            {p.totalInvoice}
          </Heading>
        </Flex>
      ),
    },
    {
      label: "",
      data: (p: any) => (
        <Flex
          alignItems="center"
          gap="16px"
          cursor="pointer"
          onClick={() => {
            setSelectedItem(p);
            onOpenDetail();
          }}
        >
          <span className="material-symbols-outlined">article</span>
        </Flex>
      ),
    },
  ];
  return (
    <>
      <Table>
        <Thead>
          <Tr backgroundColor="white">
            {columns.map((c) => (
              <Td
                backgroundColor="white"
                position={"sticky"}
                w={c.width}
                right={c.label.length === 0 ? 0 : undefined}
                top={0}
                zIndex={10}
              >
                <Heading size="md">{c.label}</Heading>
              </Td>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((d) => (
            <Tr>
              {columns.map((c) => (
                <Td
                  w={c.width}
                  backgroundColor="white"
                  position={c.label.length === 0 ? "sticky" : undefined}
                  right={c.label.length === 0 ? 0 : undefined}
                  whiteSpace="pre-line"
                >
                  {c.data(d)}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};

export default FinancialTable;
