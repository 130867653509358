import { Chart } from "react-google-charts";
import { GraphValueViewModel } from "../../../models/Dashboard";
import { useEffect, useState } from "react";

const MapChart = ({
  data,
  graphType,
  formatter,
  maxColor,
  minColor,
  isMobile,
}: {
  data: GraphValueViewModel[];
  formatter: Intl.NumberFormat;
  graphType: string;
  maxColor: string;
  minColor: string;
  isMobile: boolean;
}) => {
  return (
    <Chart
      chartLanguage="pt-BR"
      width="100%"
      height={isMobile ? 300 : 600}
      options={{
        legend: "none",
        colorAxis: { colors: [minColor, maxColor] },
      }}
      chartType="GeoChart"
      data={[
        ["Country", graphType === "1" ? "Invoice (USD)" : "Processos"],
        ...data.map((v) => [
          v.code,
          graphType === "1" ? v.usd : v.numberOfProcesses,
        ]),
      ]}
    />
  );
};
export default MapChart;
