import { Box, Flex } from "@chakra-ui/react";

const LoadingTable = ({ columns = 5 }: { columns?: number }) => {
  const renderColumns = () => {
    const columnsToRender = [];
    for (let c = 0; c < columns; c++) {
      columnsToRender.push(
        <Box key={c} height="250px" width="150px" borderWidth="0.5px"></Box>
      );
    }
    return columnsToRender;
  };
  return (
    <Box height="450px">
      <Flex>
        <Box
          className="loading"
          height="50px"
          width={`${150 * columns}px`}
          borderWidth="1px"
        ></Box>
      </Flex>
      <Flex>{renderColumns()}</Flex>
    </Box>
  );
};

export default LoadingTable;
