import { useState } from "react";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  Select,
  Text,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faClose } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";
import { Tag } from "../models/Filter";
import { Form, useForm } from "react-hook-form";

const GeneralTagFilter = ({
  tags,
  setTags,
  types,
}: {
  tags: Tag[];
  setTags: any;
  types: { label: string; type: string }[];
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [selectedType, setSelectedType] = useState("Ncm");
  const [selectedValue, setSelectedValue] = useState<string>("");
  const { register, handleSubmit, formState, getValues, reset } = useForm();

  return (
    <form onSubmit={handleSubmit((d) => {})}>
      <Flex flexDirection={isMobile ? "column" : "row"}>
        <Select
          name="type"
          value={selectedType}
          onChange={(e) => setSelectedType(e.target.value)}
        >
          {types.map((t) => (
            <option key={t.label} value={t.label}>
              {t.label}
            </option>
          ))}
        </Select>
        <Input
          name="value"
          value={selectedValue}
          onChange={(e) => setSelectedValue(e.target.value)}
        />
        <IconButton
          onClick={() => {
            if (
              selectedValue.length > 0 &&
              !tags.some(
                (t) => t.label === selectedType && t.value === selectedValue
              )
            ) {
              setTags((tags: Tag[]) => [
                ...tags,
                {
                  type: types.find((t) => t.label === selectedType)?.type,
                  label: selectedType,
                  value: selectedValue,
                },
              ]);
              setSelectedValue("");
            }
          }}
          aria-label="Add"
          type="submit"
          icon={<FontAwesomeIcon icon={faAdd} />}
        />
      </Flex>
      <Flex
        flexWrap="wrap"
        gap="12px"
        maxHeight="130px"
        overflowY={
          `${tags.map((t) => t.label).join("")}${tags
            .map((t) => t.value)
            .join("")}}`.length > 60
            ? "scroll"
            : "hidden"
        }
        marginY="24px"
      >
        {tags.map((tag) => (
          <Flex
            backgroundColor="#C0DBEA"
            borderColor="#5A96E3"
            borderRadius="3px"
            borderWidth="1px"
            height="32px"
            padding="4px 8px"
            color="#0E2954"
            alignItems="center"
            gap="8px"
            cursor="pointer"
            onClick={() =>
              setTags((tags: Tag[]) => {
                const newTags = tags.map((t) => t);
                newTags.splice(
                  tags.findIndex(
                    (t) => t.label === tag.label && t.value === tag.value
                  ),
                  1
                );
                return newTags;
              })
            }
          >
            <Text fontWeight="semibold">{tag.label}</Text>
            <Text fontWeight="semibold">:</Text>
            <Text fontWeight="semibold">{tag.value}</Text>
            <FontAwesomeIcon icon={faClose} color="#0E2954" />
          </Flex>
        ))}
      </Flex>
      {tags.length > 0 && (
        <Button onClick={() => setTags([])}>Limpar filtros</Button>
      )}
    </form>
  );
};

export default GeneralTagFilter;
