import { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Flex,
  Text,
  Select,
  Heading,
  Button,
} from "@chakra-ui/react";
import { useMediaQuery } from "react-responsive";
import NestedTableRow from "./NestedTableRow";
import LoadingTable from "./LoadingTable";
import Pagination from "@mui/material/Pagination";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const TableWithPagination = ({
  columns,
  tags,
  get,
  emptyMessage,
  nestedColumns,
  pageIndex,
  setPageIndex,
  pageSize,
  setPageSize,
  page,
  financial = false,
  hideTable = false,
  setPage,
}: {
  hideTable?: boolean;
  columns: { label: string; data: any; width?: string[] | string | number }[];
  nestedColumns?: {
    label: string;
    data: any;
    width?: string[] | string | number;
  }[];
  financial?: boolean;
  tags: any;
  get: any;
  pageIndex: number;
  setPageIndex: any;
  pageSize: number;
  setPageSize: any;
  emptyMessage: string;
  page: any[];
  setPage: any;
}) => {
  const [pageCount, setPageCount] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const gotoPage = async (index: number, localPageSize?: number) => {
    setLoading(true);
    setPageIndex(index);
    const localPs = localPageSize ?? pageSize;

    const response = await get(localPs, index);

    setPage(response.data ?? response);
    recalculateTable(response.totalCount, localPs, index);
    setLoading(false);
  };

  const recalculateTable = async (
    total: number,
    localPageSize?: number,
    index?: number
  ) => {
    const currentPageSize = localPageSize ?? pageSize;
    const defaultPageCount =
      Math.floor(total / currentPageSize) +
      (total % currentPageSize > 0 ? 1 : 0);
    setPageCount(defaultPageCount);
    setTotalCount(total);
  };

  useEffect(() => {
    gotoPage(0);
  }, [tags]);
  return loading ? (
    <LoadingTable />
  ) : (
    <>
      {!hideTable && (
        <Table fontFamily="Inter">
          <Thead>
            <Tr>
              {columns.map((column) => (
                <Th
                  textTransform="none"
                  key={column.label}
                  width={column.width}
                >
                  <Heading size="md" color="black">
                    {column.label}
                  </Heading>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {page.length == 0 ? (
              <Text margin="32px">{emptyMessage}</Text>
            ) : (
              page.map((row) => (
                <NestedTableRow
                  {...{ financial, row, columns, nestedColumns, isMobile }}
                />
              ))
            )}
          </Tbody>
        </Table>
      )}

      <Flex
        w="100%"
        justifyContent="space-between"
        flexDir={{ base: "column", md: "row" }}
        gap={{ base: "12px", md: 0 }}
        mt="32px"
        px={{ base: "5%", md: 0 }}
      >
        <Flex alignItems="center" gap="12px">
          <Pagination
            color="primary"
            size="large"
            page={pageIndex + 1}
            onChange={(e, pageNumber) => gotoPage(pageNumber - 1)}
            count={pageCount}
            siblingCount={0}
            variant="outlined"
            shape="rounded"
          />
          {!isMobile && (
            <Text size="md">
              {totalCount === 0 ? 0 : pageIndex * pageSize + 1} -{" "}
              {pageIndex * pageSize + pageSize > totalCount
                ? totalCount
                : pageIndex * pageSize + pageSize}{" "}
              de {totalCount}
            </Text>
          )}
        </Flex>
        <Flex alignItems="center" gap="12px" mb={{ base: "32px", md: 0 }}>
          <Text size="md">Linhas por página</Text>
          <Select
            w={24}
            backgroundColor="white"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              gotoPage(0, Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Select>
        </Flex>
      </Flex>
    </>
  );
};

export default TableWithPagination;
