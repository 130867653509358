import { Button, Flex, Heading } from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { ProcessViewModel } from "../../../models/Process";

const FinancialCard = ({
  p,
  onOpen,
  setSelectedItem,
}: {
  p: ProcessViewModel;
  setSelectedItem: Dispatch<SetStateAction<ProcessViewModel>>;
  onOpen: () => void;
}) => {
  const formatterUsd = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "USD",
  });

  return (
    <Flex
      flexDir="column"
      p="16px"
      gap="24px"
      borderRadius="8px"
      backgroundColor="white"
    >
      <Flex>
        <Flex flexDir="column" mt="10px" w="200px">
          <Heading size="sm" fontWeight="semibold" mb="4px">
            Nº processo
          </Heading>
          <Heading size="md" fontWeight="semibold">
            {p.cvfRef}
          </Heading>
        </Flex>

        <Flex flexDir="column" mt="10px">
          <Heading size="sm" fontWeight="semibold" mb="4px">
            Total invoice
          </Heading>

          <Heading size="md" fontWeight="normal">
            {p.totalInvoice}
          </Heading>
        </Flex>
      </Flex>
      <Flex flexDir="column" mt="10px">
        <Heading size="sm" fontWeight="semibold" mb="4px">
          Termo de pagamento
        </Heading>

        <Heading size="md" fontWeight="normal">
          {p.condition}
        </Heading>
      </Flex>

      <Flex>
        <Flex flexDir="column" mt="10px" w="200px">
          <Heading size="sm" fontWeight="semibold" mb="4px">
            Total pago
          </Heading>

          <Heading size="md" color="red.600" fontWeight="normal">
            {formatterUsd.format(parseFloat(p.totalPayed))}
          </Heading>
        </Flex>
        <Flex flexDir="column" mt="10px">
          <Heading size="sm" fontWeight="semibold" mb="4px">
            Total a pagar
          </Heading>

          <Heading size="md" color="green.600" fontWeight="normal">
            {formatterUsd.format(parseFloat(p.totalToPay))}
          </Heading>
        </Flex>
      </Flex>

      <Button
        onClick={() => {
          setSelectedItem(p);
          onOpen();
        }}
        backgroundColor="transparent"
        color="black"
        borderWidth="1px"
        mt="24px"
        borderColor="black"
      >
        <Flex justifyContent="center" alignItems="center" gap="8px">
          VISUALIZAR PARCELAS
          <span className="material-symbols-outlined">article</span>
        </Flex>
      </Button>
    </Flex>
  );
};
export default FinancialCard;
