import { useEffect, useState } from "react";
import { Box, Button, Flex, Heading, Select, useToast } from "@chakra-ui/react";
import { useMediaQuery } from "react-responsive";
import { getCompanies } from "../../../services/companyService";
import { emulate } from "../../../services/userService";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { FaUsers } from "react-icons/fa";

const Emulation = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState<any[]>([]);
  const [company, setCompany] = useState<any>([]);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const auth = useAuthUser<any>();
  const getCompaniesOptions = async () => {
    const response = await getCompanies();
    if (response) {
      setCompanies(response);
      setLoading(false);
    }
  };
  useEffect(() => {
    getCompaniesOptions();
  }, []);

  const updateUserFunction = async () => {
    setLoading(true);
    const response = await emulate({
      id: auth?.userId,
      companyId: company,
    });
    if (response) {
      setLoading(false);
      toast({
        title: "Usuário editado.",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return (
    <Box width="100%">
      <Heading size="lg" fontWeight="normal" mb="24px">
        <Flex alignItems="center" gap="12px">
          <span
            className="material-symbols-outlined"
            style={{ fontSize:'32px', color: "var(--icon-color)" }}
          >
            group
          </span>{" "}
          Emular clientes
        </Flex>
      </Heading>
      <Box backgroundColor="white" borderRadius="8px" p="24px">
        <Heading size="sm" fontWeight="normal" mb="8px">
          Empresa
        </Heading>
        <Select value={company} onChange={(e) => setCompany(e.target.value)}>
          {companies.map((c) => (
            <option key={c.id} value={c.id}>
              {c.name}
            </option>
          ))}
        </Select>
      </Box>
      <Flex justifyContent="flex-end" mb="32px">
        <Button
          marginTop="32px"
          onClick={() => updateUserFunction()}
          isLoading={loading}
          backgroundColor="var(--icon-color)"
          color="white"
        >
          Emular
        </Button>
      </Flex>
    </Box>
  );
};

export default Emulation;
