import {
  ButtonGroup,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Select,
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  Table,
  Text,
  useDisclosure,
  ModalHeader,
  CloseButton,
} from "@chakra-ui/react";
import Flag from "react-world-flags";
import { GraphValueViewModel } from "../../../models/Dashboard";

const ShowMoreModal = ({
  isOpen,
  onClose,
  formatter,
  graphType,
  values,
  title,
}: {
  isOpen: boolean;
  onClose: () => void;
  formatter: Intl.NumberFormat;
  graphType: string;
  values: GraphValueViewModel[];
  title: string;
}) => {
  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      isCentered
    >
      <ModalOverlay />
      <ModalContent borderRadius="12px">
        <ModalHeader>
          <Flex justifyContent="space-between">
            <Heading size="lg" fontWeight="semibold">
              {title[0]}
              {title.substring(1).toLowerCase()}
            </Heading>
            <CloseButton onClick={onClose} />
          </Flex>
        </ModalHeader>
        <ModalBody p="24px">
          <Flex flexDir="column" gap="24px">
            {values.map((v) => (
              <Flex justifyContent="space-between">
                <Flex gap="24px">
                  <Flag code={v.code} width="42px" />
                  <Heading size="md" fontWeight="normal">
                    {v.label}
                  </Heading>
                </Flex>
                <Heading size="md">
                  {graphType === "1"
                    ? formatter.format(v.usd)
                    : v.numberOfProcesses}
                </Heading>
              </Flex>
            ))}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ShowMoreModal;
