import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Checkbox,
} from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import { AxiosError } from "axios";
import { getPermissionsByToken, signUp } from "../../services/userService";
import { FieldValue, useForm } from "react-hook-form";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import { useMediaQuery } from "react-responsive";
import { DateTime, Duration } from "luxon";

const SignUp = () => {
  const { register, handleSubmit, formState, getValues } = useForm();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [permissions, setPermissions] = useState<string[]>();
  const [showPassword, setShowPassword] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const url = useSearchParams();

  const handleShowClick = () => setShowPassword(!showPassword);

  const navigate = useNavigate();

  const signIn = useSignIn();

  const getPermissions = async (token: string) => {
    const response = await getPermissionsByToken(token);

    if (response) {
      setPermissions(response.permissions);
    }
  };
  const signUpUser = async (data: any) => {
    setLoading(true);

    try {
      const response = await signUp({
        password: data.password,
        token: token ?? "",
        document: data.document,
        firstname: data.firstname,
        lastname: data.lastname,
        receiveNotification: data.receiveNotification,
      });

      if (response?.data.token) {
        const expiration = 86400000;

        signIn({
          auth: {
            token: response.data.token,
            type: "Bearer",
          },
          userState: {
            userId: response.data.user.id,
            name: response.data.user.name,
            firstname: response.data.user.firstname,
            lasstname: response.data.user.lasstname,
            company: response.data.user.company,
            admin: response.data.user.admin,
            permissions: response.data.user.permissions,
            expiresIn: DateTime.now().plus(Duration.fromMillis(expiration)),
          },
        });
        navigate("/");
      } else {
        setError("Token inválido");
      }
    } catch (err) {
      if (err && err instanceof AxiosError) console.log("Error: ", err);
    }
    setLoading(false);
  };

  const isAuth = useIsAuthenticated();

  useEffect(() => {
    const tokenUrl = url[0].get("token");
    if (isAuth) {
      navigate("/");
    }
    if (tokenUrl?.length === 36) {
      setToken(tokenUrl);
      getPermissions(tokenUrl);
    }
  }, []);

  const validateRequired = (value: FieldValue<Record<string, any>>) =>
    !value || /^\s*$/.test(value) ? "Esse campo é obrigatório." : undefined;

  return (
    <Box
      height="100vh"
      overflowX="hidden"
      backgroundColor="whitesmoke"
      padding={isMobile ? "0px" : "64px"}
    >
      <Flex width="100%" justifyContent="center" alignItems="center">
        <Box>
          <Flex
            position="relative"
            width={isMobile ? "45vh" : "60vh"}
            backgroundColor="white"
            padding="64px"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            boxShadow="3px 3px 12px -3px rgba(0,0,0,0.06)"
          >
            <Heading>Cadastro de Usuário</Heading>
            <form onSubmit={handleSubmit(signUpUser)} style={{ width: "100%" }}>
              <Box marginY="64px">
                <Flex>
                  <Heading size="sm" marginBottom="12px">
                    Primeiro nome
                  </Heading>
                  <Text color="red" fontSize="18px" marginLeft="4px">
                    *
                  </Text>
                </Flex>
                <InputGroup>
                  <Input
                    type="text"
                    placeholder="Primeiro nome"
                    {...register("firstname", {
                      required: "Esse campo é obrigatório.",
                    })}
                  />
                </InputGroup>
                {formState.errors.firstname && (
                  <div
                    style={{
                      color: "red",
                      marginTop: "16px",
                      marginBottom: "-40px",
                    }}
                  >
                    {typeof formState.errors.firstname.message === "string" &&
                      formState.errors.firstname.message}
                  </div>
                )}
              </Box>
              <Box marginY="64px">
                <Flex>
                  <Heading size="sm" marginBottom="12px">
                    Sobrenome
                  </Heading>
                  <Text color="red" fontSize="18px" marginLeft="4px">
                    *
                  </Text>
                </Flex>
                <InputGroup>
                  <Input
                    type="text"
                    placeholder="Sobrenome"
                    required
                    {...register("lastname", {
                      required: "Esse campo é obrigatório.",
                    })}
                  />
                </InputGroup>
                {formState.errors.lastname && (
                  <div
                    style={{
                      color: "red",
                      marginTop: "16px",
                      marginBottom: "-40px",
                    }}
                  >
                    {typeof formState.errors.lastname.message === "string" &&
                      formState.errors.lastname.message}
                  </div>
                )}
              </Box>
              <Box marginY="64px">
                <Flex>
                  <Heading size="sm" marginBottom="12px">
                    Senha
                  </Heading>
                  <Text color="red" fontSize="18px" marginLeft="4px">
                    *
                  </Text>
                </Flex>
                <InputGroup>
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Senha"
                    required
                    {...register("password", {
                      required: "Esse campo é obrigatório.",
                    })}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      backgroundColor="transparent"
                      size="sm"
                      onClick={handleShowClick}
                    >
                      {showPassword ? (
                        <FontAwesomeIcon icon={faEyeSlash} />
                      ) : (
                        <FontAwesomeIcon icon={faEye} />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {formState.errors.password && (
                  <div
                    style={{
                      color: "red",
                      marginTop: "16px",
                      marginBottom: "-40px",
                    }}
                  >
                    {typeof formState.errors.password.message === "string" &&
                      formState.errors.password.message}
                  </div>
                )}
              </Box>

              <Box>
                <Flex>
                  <Heading size="sm" marginBottom="12px">
                    Confirmar senha
                  </Heading>
                  <Text color="red" fontSize="18px" marginLeft="4px">
                    *
                  </Text>
                </Flex>
                <InputGroup>
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Senha"
                    required
                    {...register("confirmPassword", {
                      required: "Esse campo é obrigatório.",
                      validate: (value: FieldValue<Record<string, any>>) => {
                        const requiredValid = validateRequired(value);
                        const isEqual = getValues().password == value;

                        return (
                          requiredValid ??
                          (isEqual ? undefined : "Senhas devem coincidir")
                        );
                      },
                    })}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      backgroundColor="transparent"
                      size="sm"
                      onClick={handleShowClick}
                    >
                      {showPassword ? (
                        <FontAwesomeIcon icon={faEyeSlash} />
                      ) : (
                        <FontAwesomeIcon icon={faEye} />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {(formState.errors.confirmPassword || error) && (
                  <div
                    style={{
                      color: "red",
                      marginTop: "16px",
                    }}
                  >
                    {error ??
                      (typeof formState.errors.confirmPassword?.message ===
                        "string" &&
                        formState.errors.confirmPassword.message)}
                  </div>
                )}
              </Box>
              {permissions?.some((p) => p === "Tracking") && (
                <Checkbox mt="32px" {...register("receiveNotification")}>
                  Receber notificações de containers no e-mail.
                </Checkbox>
              )}
              <Button
                marginY="32px"
                borderRadius="3px"
                type="submit"
                variant="solid"
                backgroundColor="#29234b"
                color="white"
                width="full"
                isLoading={loading}
              >
                Cadastrar
              </Button>
            </form>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
export default SignUp;
