import { DateTime } from "luxon";

export interface DashboardViewModel {
  totalInvoice: string;
  totalInvoiceBefore: number;
  count: number;
  countBefore: number;
  transitTime: string;
  transitTimeBefore: number;
  nacionalization: string;
  nacionalizationBefore: number;
  leadTimeBefore: number;
  leadTime: string;
  graphs: GraphViewModel[];
}

export interface GraphViewModel {
  title: string;
  values: GraphValueViewModel[];
  before?: number;
}

export interface GraphValueViewModel {
  label: string;
  numberOfProcesses: number;
  code: string;
  ncm: string;
  origin: string;
  usd: number;
  color: string;
}

export interface DashboardQuery {
  processId?: string;
  dateType?: string;
  initialDate?: string;
  graphType?: string;
  finalDate?: string;
  ncm?: string;
  departurePort?: string;
  dischargePort?: string;
  companyId?: string;
  countryOfOrigin?: string;
  cvfRef?: string;
  exporter?: string;
  name?: string;
  product?: string;
  pageNumber?: number;
  pageSize?: number;
  cnyValue?: string;
  eurValue?: string;
  jpyValue?: string;
  chfValue?: string;
  sekValue?: string;
}

export const dashboardColors = [
  "#0AA44E",
  "#DEBE37",
  "#B2153C",
  "#459EEB",
  "#78EA89",
  "#CF8FA4",
  "#C6C2D3",
  "#BD18BC",
  "#3D9AB6",
  "#D9122C",
  "#DA7D41",
  "#8CC7BB",
  "#107DF0",
  "#57F3F3",
  "#8AA657",
  "#BA4F0A",
  "#7C21F3",
  "#9F8C91",
  "#9D6196",
  "#108B6B",
  "#A4E5ED",
  "#319D29",
  "#2CEC5A",
  "#5037FA",
  "#729683",
  "#5AA539",
  "#EFE288",
  "#94B3D9",
  "#06EAB9",
  "#5001E6",
];
