import { useState, useEffect } from "react";
import { Box, Flex, Heading, Image, Link, Spinner } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowTrendUp,
  faArrowTrendDown,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaSpinner } from "react-icons/fa";
import { getCurrency } from "../../services/currencyService";

const HomeHeader = () => {
  const [dollar, setDollar] = useState();
  const [euro, setEuro] = useState();
  const [yuen, setYuen] = useState();

  const getCurrencyAsync = async () => {
    const currency = await getCurrency();
    setDollar(currency.dollar);
    setEuro(currency.euro);
    setYuen(currency.yuen);
  };

  useEffect(() => {
    const currencyCache = localStorage.getItem("currency");
    const currencyObj = currencyCache ? JSON.parse(currencyCache) : {};
    if (
      currencyCache &&
      DateTime.fromISO(currencyObj.date).equals(DateTime.now().startOf("day"))
    ) {
      setDollar(currencyObj.dollar);
      setEuro(currencyObj.euro);
      setYuen(currencyObj.yuen);
    } else {
      getCurrencyAsync();
    }
  }, []);
  return (
    <>
      {/* <Link href="/">
        <Image src="logo.png" height="40px" marginRight="100px" />
      </Link> */}
      <Box>
        <Box>
          {!dollar ? (
            <Flex alignItems="center" justifyContent="center">
              <Spinner className="spinner" />
            </Flex>
          ) : (
            <Flex
              flexDir={{ base: "column", md: "row" }}
              justifyContent="space-between"
              gap="12px"
              w="100%"
            >
              <div>
                <Flex alignItems="center" gap="4px" w="350px">
                  <Heading
                    size="md"
                    fontWeight="semibold"
                    textAlign="right"
                    marginRight="4px"
                  >
                    USD/BRL (Dólar):
                  </Heading>
                  <Heading
                    color={dollar?.increased ? "green" : "red"}
                    size="md"
                  >
                    R$ {dollar?.value}
                  </Heading>
                  {dollar?.increased ? (
                    <FontAwesomeIcon icon={faArrowTrendUp} color="green" />
                  ) : (
                    <FontAwesomeIcon icon={faArrowTrendDown} color="red" />
                  )}
                </Flex>
              </div>
              <div>
                <Flex alignItems="center" gap="4px" w="350px">
                  <Heading
                    size="md"
                    fontWeight="semibold"
                    textAlign="right"
                    marginRight="4px"
                  >
                    EUR/BRL (Euro):
                  </Heading>
                  <Heading color={euro?.increased ? "green" : "red"} size="md">
                    R$ {euro?.value}
                  </Heading>
                  {euro?.increased ? (
                    <FontAwesomeIcon icon={faArrowTrendUp} color="green" />
                  ) : (
                    <FontAwesomeIcon icon={faArrowTrendDown} color="red" />
                  )}
                </Flex>
              </div>
              <div>
                <Flex alignItems="center" gap="4px" w="350px">
                  <Heading
                    size="md"
                    fontWeight="semibold"
                    textAlign="right"
                    marginRight="4px"
                  >
                    CNY/BRL (Yuen Chinês):
                  </Heading>
                  <Heading color={yuen?.increased ? "green" : "red"} size="md">
                    R$ {yuen?.value}
                  </Heading>
                  {yuen?.increased ? (
                    <FontAwesomeIcon icon={faArrowTrendUp} color="green" />
                  ) : (
                    <FontAwesomeIcon icon={faArrowTrendDown} color="red" />
                  )}
                </Flex>
              </div>
            </Flex>
          )}
        </Box>
        {/* <Box h="30px">
          <Flex>
            <div>
              <Flex alignItems="center" gap="4px" w="350px">
                <Heading
                  w="200px"
                  fontWeight="semibold"
                  textAlign="right"
                  size="md"
                  marginRight="4px"
                >
                  Nova York
                </Heading>
                <FontAwesomeIcon
                  icon={faClock}
                  style={{ marginRight: "4px" }}
                />
                <Heading size="md" w="460px">
                  {DateTime.now().setZone("America/New_York").toFormat("HH:mm")}
                </Heading>
              </Flex>
            </div>

            <div>
              <Flex
                alignItems="center"
                justifyContent="center"
                gap="4px"
                w="350px"
              >
                <Heading
                  fontWeight="semibold"
                  textAlign="right"
                  size="md"
                  marginRight="4px"
                >
                  Londres
                </Heading>
                <FontAwesomeIcon
                  icon={faClock}
                  style={{ marginRight: "4px" }}
                />
                <Heading size="md" w="460px">
                  {DateTime.now().setZone("Europe/London").toFormat("HH:mm")}
                </Heading>
              </Flex>
            </div>
            <div>
              <Flex
                alignItems="center"
                justifyContent="center"
                gap="4px"
                w="350px"
              >
                <Heading
                  fontWeight="semibold"
                  textAlign="right"
                  size="md"
                  marginRight="4px"
                >
                  Dubai
                </Heading>
                <FontAwesomeIcon
                  icon={faClock}
                  style={{ marginRight: "4px" }}
                />
                <Heading size="md" w="460px">
                  {DateTime.now().setZone("Asia/Dubai").toFormat("HH:mm")}
                </Heading>
              </Flex>
            </div>
            <div>
              <Flex
                alignItems="center"
                justifyContent="center"
                gap="4px"
                w="350px"
              >
                <Heading
                  fontWeight="semibold"
                  textAlign="right"
                  size="md"
                  marginRight="4px"
                >
                  Shanghai
                </Heading>
                <FontAwesomeIcon
                  icon={faClock}
                  style={{ marginRight: "4px" }}
                />
                <Heading size="md" w="460px">
                  {DateTime.now().setZone("Asia/Shanghai").toFormat("HH:mm")}
                </Heading>
              </Flex>
            </div>
          </Flex>
        </Box> */}
      </Box>
    </>
  );
};

export default HomeHeader;
