import { FaCheckCircle, FaTruckMoving } from "react-icons/fa";
import { GoContainer } from "react-icons/go";
import { AiFillFileExclamation } from "react-icons/ai";

export const trackingTypes = [
  {
    id: 1,
    title: { portuguese: "Saiu da origem", english: "Departed from origin" },
    type: "left",
    icon: (isMobile: boolean) => <GoContainer fontSize={isMobile ? 35 : 70} />,
  },
  {
    id: 2,
    title: {
      portuguese: "Objeto recebido na unidade de exportação",
      english: "Item received at export unit",
    },
    type: "export",
    icon: (isMobile: boolean) => (
      <img src="port.png" width={isMobile ? 40 : 80} />
    ),
  },
  {
    id: 3,
    title: { portuguese: "Objeto em trânsito", english: "Item in transit" },
    type: "moving",
    icon: (isMobile: boolean) => (
      <img src="cargo-ship.png" width={isMobile ? 45 : 90} />
    ),
    // icon: (isMobile:boolean) => <GiCargoShip fontSize={isMobile ? 70:70} />,
    // icon: (isMobile:boolean) => <TbShip fontSize={isMobile ? 70:70} />,
  },
  {
    id: 4,
    title: {
      portuguese: "Objeto recebido no canal verde",
      english: "Item received at import unit",
    },
    type: "channelGreen",
    icon: (isMobile: boolean) => (
      <img src="channel-green.png" width={isMobile ? 40 : 80} />
    ),
  },
  {
    id: 5,
    title: {
      portuguese: "Objeto recebido no canal cinza",
      english: "Item received at import unit",
    },
    type: "channelYellow",
    icon: (isMobile: boolean) => (
      <img src="channel-gray.png" width={isMobile ? 40 : 80} />
    ),
  },
  {
    id: 6,
    title: {
      portuguese: "Objeto recebido no canal vermelho",
      english: "Item received at import unit",
    },
    type: "channelRed",
    icon: (isMobile: boolean) => (
      <img src="channel-red.png" width={isMobile ? 40 : 80} />
    ),
  },
  {
    id: 7,
    title: {
      portuguese: "Objeto recebido na unidade de importação",
      english: "Item received at import unit",
    },
    type: "import",
    icon: (isMobile: boolean) => (
      <img src="port.png" width={80} style={{ transform: "scaleX(-1)" }} />
    ),
  },
  {
    id: 8,
    title: {
      portuguese: "Saiu para fiscalização",
      english: "Departed for inspection",
    },
    type: "fiscalization",
    icon: (isMobile: boolean) => (
      <AiFillFileExclamation fontSize={isMobile ? 35 : 70} />
    ),
  },
  {
    id: 9,
    title: {
      portuguese: "Em trânsito local",
      english: "Departed for inspection",
    },
    type: "localTransit",
    icon: (isMobile: boolean) => (
      <FaTruckMoving fontSize={isMobile ? 35 : 70} />
    ),
  },
  {
    id: 10,
    title: {
      portuguese: "Booking agendado",
      english: "Item received at export unit",
    },
    type: "booking",
    icon: (isMobile: boolean) => (
      <img src="port.png" width={isMobile ? 40 : 80} />
    ),
  },
  {
    id: 11,
    title: {
      portuguese: "Entregue",
      english: "Delivered",
    },
    type: "delivered",
    icon: (isMobile: boolean) => (
      <FaCheckCircle color="green" fontSize={isMobile ? 35 : 70} />
    ),
  },
];
