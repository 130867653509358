import api from "./api";

export const getPermissionsByCompanyId = async (companyId: string) => {
  try {
    const response = await api.get(`/api/permission/${companyId}`);
    if (!response.data.success) {
      console.log("error", response.data);
    }
    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const getPermissions = async () => {
  try {
    const response = await api.get(`/api/permission/`);
    if (!response.data.success) {
      console.log("error", response.data);
    }
    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const savePermission = async (permissionName: string) => {
  try {
    const response = await api.post(`/api/permission`, {
      name: permissionName,
    });
    if (!response.data.success) {
      console.log("error", response.data.notifications);
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const deletePermission = async (permissionId: string) => {
  try {
    const response = await api.delete(`/api/permission/${permissionId}`);
    if (!response.data.success) {
      console.log("error", response.data.notifications);
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
};
