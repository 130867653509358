import {
  Box,
  Flex,
  Heading,
  Link,
  Progress,
  Text,
  VStack,
} from "@chakra-ui/react";
import Header from "../../components/Header/Header";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useLocation } from "react-router-dom";
import { faAngleLeft, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import Track from "./components/Track";
import { getTrackingById } from "../../services/trackingService";
import { DateTime } from "luxon";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { getShipsGoByProcessId } from "../../services/shipsGoService";
import { FaAngleRight } from "react-icons/fa";
import { EventAvailable, EventAvailableOutlined } from "@mui/icons-material";
import TimelineItem from "./components/TimelineItem";

const Tracking = () => {
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  const navigate = useNavigate();
  const auth = useAuthUser<any>();
  const [processRef, setProcessRef] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [oldStyle, setOldStyle] = useState(
    !auth.permissions.some((p: string) => p === "Tracking")
  );
  const [data, setData] = useState<any>({});

  const get = async () => {
    if (id) {
      const response = await getShipsGoByProcessId(id);
      const isOldStyle = !!response.data;
      setOldStyle(isOldStyle);
      setProcessRef(response.cvfRef);
      setData(isOldStyle ? response.data : response);
      setLoading(false);
    }
  };

  useEffect(() => {
    get();
  }, [id]);
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(interval);
        }
        return Math.min(oldProgress + 1, 100);
      });
    }, 50);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box>
      <Header />
      <Flex
        height="100%"
        flexDirection="column"
        backgroundColor="var(--main-bg-color)"
        paddingBottom="32px"
        alignItems="center"
      >
        <Flex
          alignItems="center"
          gap="8px"
          mb="48px"
          width="90%"
          mt={{ base: "16px", md: "27px" }}
        >
          <Link
            color="var(--icon-color)"
            onClick={() => navigate("/processes")}
            size="md"
          >
            Processos
          </Link>
          <FaAngleRight />
          <Link
            color="var(--icon-color)"
            onClick={() => navigate(`/process?id=${id}`)}
            size="md"
          >
            Detalhes
          </Link>
          <FaAngleRight />
          <Text size="md">Tracking</Text>
        </Flex>

        <Flex width="90%">
          <Heading size="lg" fontWeight="normal" mb="32px">
            Rastreamento do Processo {data?.cvfRef}
          </Heading>
        </Flex>
        <Flex width="90%">
          <Flex
            borderRadius="8px"
            backgroundColor="white"
            width="100%"
            padding="32px"
            paddingLeft={{ base: "32px", md: "64px" }}
            flexDirection="column"
          >
            {loading ? (
              <Flex
                w="100%"
                justifyContent="center"
                flexDir="column"
                alignItems="center"
                py="120px"
              >
                <Heading>Obtendo informações</Heading>
                <Progress
                  w="500px"
                  value={progress}
                  size="sm"
                  colorScheme="blue"
                />
              </Flex>
            ) : oldStyle ? (
              <>
                <Flex wrap="wrap" paddingBottom="32px">
                  {data?.map((d: any, index: number) => (
                    <Track
                      processId={id ?? ""}
                      setData={setData}
                      get={get}
                      track={d}
                      isLast={index === data?.length - 1}
                    />
                  ))}

                  {auth?.admin && (
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                      height="25vh"
                      borderWidth="3px"
                      borderStyle="dashed"
                      borderColor="black"
                      width="20vh"
                      gap="16px"
                      marginLeft="32px"
                      cursor="pointer"
                      onClick={() =>
                        data?.every((d: any) => d.id) &&
                        setData([
                          ...data,
                          {
                            type: 0,
                            dateTime: DateTime.now().toISO() ?? "",
                            countryId: 1,
                          },
                        ])
                      }
                    >
                      <FontAwesomeIcon fontSize="28px" icon={faPlusCircle} />
                      <Heading size="lg">Adicionar</Heading>
                    </Flex>
                  )}
                </Flex>
              </>
            ) : (
              <>
                <Flex
                  w="100%"
                  gap="64px"
                  flexDir={{ base: "column", md: "row" }}
                >
                  <Box>
                    <Box>
                      <Flex flexDir="column" mb="64px">
                        <Flex alignItems="flex-start" gap="12px">
                          <EventAvailableOutlined
                            color="primary"
                            fontSize="medium"
                          />
                          <Heading size="sm" fontWeight="semibold">
                            Data de chegada
                            {data?.arrivalDate.isActual ? "" : " estimada"}:
                          </Heading>
                        </Flex>
                        <Heading size="lg" ml="38px">
                          {DateTime.fromISO(data?.arrivalDate.date).toFormat(
                            "dd/LL/yyyy"
                          )}
                        </Heading>
                      </Flex>
                    </Box>

                    <Box mb="32px">
                      <VStack spacing={0} align="stretch">
                        {data?.gateInDate && (
                          <TimelineItem
                            place={data?.pol}
                            isActual={
                              DateTime.fromISO(data?.gateInDate).startOf(
                                "day"
                              ) <= DateTime.now().startOf("day")
                            }
                            arrivalDate={data?.gateInDate}
                            isFirst
                          />
                        )}
                        <TimelineItem
                          place={`${data?.pol}, ${data?.fromCountry}`}
                          isActual={data?.departureDate.isActual}
                          departureDate={data?.departureDate.date}
                          isFirst={!data?.gateInDate}
                        />
                        {data?.tsPorts.map((ts: any, index: number) => (
                          <>
                            <TimelineItem
                              isFirst
                              isTransshipment
                              place={ts.port}
                              isActual={ts.departureDate.isActual}
                              arrivalDate={ts.departureDate.date}
                            />
                            <TimelineItem
                              isTransshipment
                              place={ts.port}
                              isActual={ts.arrivalDate.isActual}
                              departureDate={ts.arrivalDate.date}
                            />
                          </>
                        ))}
                        <TimelineItem
                          place={`${data?.pod}, ${data?.toCountry}`}
                          isActual={data?.arrivalDate.isActual}
                          arrivalDate={data?.arrivalDate.date}
                        />
                        {data?.gateOutDate && (
                          <TimelineItem
                            place={data?.pod}
                            isActual={
                              DateTime.fromISO(data?.gateOutDate).startOf(
                                "day"
                              ) <= DateTime.now().startOf("day")
                            }
                            departureDate={data?.gateOutDate}
                          />
                        )}
                      </VStack>
                    </Box>
                  </Box>
                  <Box w={{ base: "100%", md: "80%" }}>
                    <Box width="100%">
                      <iframe
                        width="100%"
                        title="CVFLocationMap"
                        height="640"
                        style={{ border: 0, display: "block" }}
                        src={`https://maps.google.com/maps?q=${encodeURI(
                          `${
                            data?.vesselLatitude === "Not Supported"
                              ? data?.tsPorts.find(
                                  (t: any) =>
                                    t.vesselLatitude !== "Not Supported"
                                )?.vesselLatitude
                              : data?.vesselLatitude
                          }, ${
                            data?.vesselLongitude === "Not Supported"
                              ? data?.tsPorts.find(
                                  (t: any) =>
                                    t.vesselLongitude !== "Not Supported"
                                )?.vesselLongitude
                              : data?.vesselLongitude
                          }`
                        )}&t=&z=8&ie=UTF8&iwloc=&output=embed`}
                      />
                    </Box>
                  </Box>
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
export default Tracking;
