import { InputGroup, InputRightElement } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { DateTime } from "luxon";
import { CalendarIcon } from "@chakra-ui/icons";
import MaskedInput from "react-text-mask";
import { formatDateTime } from "../services/utils";
import { Dispatch, SetStateAction } from "react";

const DatePickerField = ({
  date,
  setDate,
  dateValue,
  setDateValue,
  width,
}: {
  date: Date | undefined;
  setDate: Dispatch<SetStateAction<Date | undefined>>;
  dateValue: string | undefined;
  setDateValue: Dispatch<SetStateAction<string | undefined>>;
  width?: string;
}) => {
  return (
    <DatePicker
      dateFormat="MM/yyyy"
      placeholderText="Select a month other than the disabled months"
      showMonthYearPicker
      selectsRange
      locale="pt-br"
      popperPlacement="bottom-start"
      selected={date}
      onChange={([newStartDate, newEndDate]) => {
        setDateValue(
          `${DateTime.fromJSDate(newStartDate ?? new Date()).toFormat(
            "LL/yyyy"
          )}`
        );
        setDate((date) => newStartDate ?? date);
      }}
      onSelect={(newStartDate: Date) => {
        setDateValue(
          `${DateTime.fromJSDate(newStartDate ?? new Date()).toFormat(
            "LL/yyyy"
          )}`
        );
        setDate(newStartDate);
      }}
      customInput={
        <InputGroup
          width={width}
          backgroundColor="white"
          className="styled-input"
        >
          <MaskedInput
            type="text"
            mask={[/\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            height="40px"
            value={dateValue}
            onChange={(e: any) =>
              setDateValue(formatDateTime(dateValue, e.target.value))
            }
          />
          <InputRightElement>
            <CalendarIcon />
          </InputRightElement>
        </InputGroup>
      }
    />
  );
};

export default DatePickerField;
