import { CalendarIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Select,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { FieldValue, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import MaskedTextInput from "react-text-mask";
import { formatDateTime } from "../../../services/utils";
import { DateTime } from "luxon";
import { trackingTypes } from "../../../models/trackingTypes";
import { countries } from "../../../models/countries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencil,
  faQuestion,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  removeTracking,
  saveTracking,
  updateTracking,
} from "../../../services/trackingService";
import { useMediaQuery } from "react-responsive";
import { Tracking } from "../../../models/Tracking";
import { FaQuestion } from "react-icons/fa";

const Track = ({
  track,
  isLast,
  get,
  setData,
  processId,
}: {
  track: Tracking;
  isLast: boolean;
  get: any;
  setData: any;
  processId: string;
}) => {
  const trackingType = trackingTypes.find((t) => t.id === track.type);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date(track.dateTime));
  const [dateValue, setDateValue] = useState(
    DateTime.fromISO(track.dateTime).toFormat("dd/LL/yyyy HH:mm")
  );
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const auth = useAuthUser<any>();
  const { register, handleSubmit, formState } = useForm();
  const save = async (data: any) => {
    setLoading(true);
    const response = track.id
      ? await updateTracking({
          ...data,
          id: track.id,
          processId,
          dateTime: selectedDate,
        })
      : await saveTracking({ ...data, processId, dateTime: selectedDate });
    if (response) {
      get();
      setEditing(false);
    }
    setLoading(false);
  };
  const remove = async () => {
    setLoading(true);
    if (track.id) {
      const response = await removeTracking(track.id);
      if (response) {
        get();
        onClose();
      }
    } else {
      setData((data: any[]) => data.filter((d) => d.id));
    }
    setLoading(false);
  };
  const validateRequired = (value: FieldValue<Record<string, any>>) =>
    !value || /^\s*$/.test(value) ? "Esse campo é obrigatório." : undefined;
  return (
    <Flex flexDirection={isMobile ? "column" : "row"}>
      {editing || !track.id ? (
        <form onSubmit={handleSubmit(save)}>
          <Flex position="relative" alignItems="center" marginY="32px">
            <Flex flexDirection="column" gap="16px">
              <Box>
                <Heading size="sm" marginBottom="8px">
                  Data
                </Heading>
                <DatePicker
                  locale="pt-br"
                  dateFormat="dd/MM/yyyy HH:mm"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeCaption="Hora"
                  popperPlacement="bottom-start"
                  selected={selectedDate}
                  onChange={(date: Date, event) => {
                    if (!event)
                      setDateValue(
                        `${date.toLocaleDateString("pt")} ${date
                          .toLocaleTimeString("pt")
                          .substring(0, 5)}`
                      );
                    setSelectedDate(date);
                  }}
                  onSelect={(date: Date) => {
                    setDateValue(
                      `${date.toLocaleDateString("pt")} ${date
                        .toLocaleTimeString("pt")
                        .substring(0, 5)}`
                    );
                    setSelectedDate(date);
                  }}
                  customInput={
                    <InputGroup>
                      <MaskedTextInput
                        type="text"
                        className="styled-input"
                        mask={[
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          " ",
                          /\d/,
                          /\d/,
                          ":",
                          /\d/,
                          /\d/,
                        ]}
                        width="30vh"
                        value={dateValue}
                        {...register("dateTime", {
                          onChange: (e: any) =>
                            setDateValue(
                              formatDateTime(dateValue, e.target.value)
                            ),
                          validate: (value: FieldValue<Record<string, any>>) =>
                            validateRequired(dateValue),
                        })}
                      />
                      <InputRightElement>
                        <CalendarIcon />
                      </InputRightElement>
                    </InputGroup>
                  }
                />
                {formState.errors.dateTime && (
                  <div style={{ color: "red", marginBottom: "16px" }}>
                    {typeof formState.errors.dateTime.message === "string" &&
                      formState.errors.dateTime.message}
                  </div>
                )}
              </Box>
              <Box>
                <Heading size="sm" marginBottom="8px">
                  Tipo
                </Heading>
                <Select
                  width="22vh"
                  defaultValue={track.type}
                  {...register("type", {
                    required: "Esse campo é obrigatório",
                  })}
                >
                  {trackingTypes.map((t) => (
                    <option key={t.id} value={t.id}>
                      {t.title.portuguese}
                    </option>
                  ))}
                </Select>
                {formState.errors.type && (
                  <div style={{ color: "red", marginBottom: "16px" }}>
                    {typeof formState.errors.type.message === "string" &&
                      formState.errors.type.message}
                  </div>
                )}
              </Box>
              <Box>
                <Heading size="sm" marginBottom="8px">
                  País
                </Heading>
                <Select
                  defaultValue={track.countryId}
                  width="22vh"
                  {...register("countryId", {
                    required: "Esse campo é obrigatório",
                  })}
                >
                  {countries.portuguese.map((c) => (
                    <option key={c.id} value={c.id}>
                      {c.name}
                    </option>
                  ))}
                </Select>
                {formState.errors.countryId && (
                  <div style={{ color: "red", marginBottom: "16px" }}>
                    {typeof formState.errors.countryId.message === "string" &&
                      formState.errors.countryId.message}
                  </div>
                )}
              </Box>
              <Button
                isLoading={loading}
                backgroundColor="#29234b"
                color="white"
                _hover={{ backgroundColor: "#5c4ead" }}
                type="submit"
              >
                Salvar
              </Button>
            </Flex>
            {!isLast && !isMobile && (
              <Box width="200px" height="4px" backgroundColor="black"></Box>
            )}
          </Flex>
        </form>
      ) : (
        <Flex
          flexDirection={isMobile ? "row" : "column"}
          alignItems="left"
          marginBottom="64px"
          gap="32px"
        >
          <Flex position="relative" alignItems="center" marginBottom="32px">
            <Flex
              alignItems="center"
              justifyContent={"center"}
              borderRadius="100%"
              borderWidth="2px"
              borderColor="black"
              width={isMobile ? "80px" : "140px"}
              height={isMobile ? "80px" : "140px"}
            >
              {trackingType?.icon(isMobile) ?? <FaQuestion fontSize={70} />}
            </Flex>
            {!isLast && !isMobile && (
              <Box width="160px" height="4px" backgroundColor="black"></Box>
            )}
          </Flex>
          <Box>
            <Heading
              marginBottom={trackingType?.id === 3 ? "16px" : undefined}
              width="200px"
              size="md"
            >
              {trackingType?.title.portuguese}
            </Heading>
            {trackingType?.id !== 3 && (
              <Text
                fontSize="18px"
                fontWeight="semibold"
                marginY="16px"
                size="md"
              >
                Em:{" "}
                {countries.portuguese.find((p) => p.id === track.countryId)
                  ?.name ?? ""}
              </Text>
            )}
            <Text fontSize="18px" fontWeight="semibold" size="md">
              {DateTime.fromISO(track.dateTime).toFormat("dd/LL/yyyy HH:mm")}
            </Text>
            {auth?.admin && !editing && (
              <Flex marginTop="16px" gap="8px">
                <Button
                  width="16vh"
                  backgroundColor="#29234b"
                  color="white"
                  onClick={() => setEditing(!editing)}
                >
                  <Flex alignItems="center" gap="8px">
                    <Heading size="md">Editar</Heading>
                    <FontAwesomeIcon
                      style={{ position: "relative", top: "2px" }}
                      icon={faPencil}
                    />
                  </Flex>
                </Button>
                <Popover
                  isOpen={isOpen}
                  onOpen={onOpen}
                  onClose={onClose}
                  placement="top"
                >
                  <PopoverTrigger>
                    <IconButton
                      isLoading={loading}
                      colorScheme="red"
                      position="relative"
                      aria-label="Deletar"
                      icon={<FontAwesomeIcon icon={faTrash} />}
                    >
                      Deletar
                    </IconButton>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverHeader fontWeight="semibold">
                      Confirmação
                    </PopoverHeader>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody>Tem certeza que deseja excluir?</PopoverBody>
                    <PopoverFooter display="flex" justifyContent="flex-end">
                      <ButtonGroup size="sm">
                        <Button
                          isLoading={loading}
                          variant="outline"
                          onClick={() => onClose()}
                        >
                          Cancelar
                        </Button>
                        <Button
                          onClick={() => {
                            remove();
                          }}
                          isLoading={loading}
                          colorScheme="red"
                          position="relative"
                          aria-label="Deletar"
                        >
                          Excluir
                        </Button>
                      </ButtonGroup>
                    </PopoverFooter>
                  </PopoverContent>
                </Popover>
              </Flex>
            )}
          </Box>
        </Flex>
      )}
      {isMobile && !isLast && (
        <Box
          marginTop={editing ? "32px" : "-100px"}
          width="3px"
          position="relative"
          height="200px"
          left={35}
          backgroundColor="black"
        ></Box>
      )}
    </Flex>
  );
};
export default Track;
