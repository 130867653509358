import axios from "axios";
import { DateTime } from "luxon";

export const baseURL = process.env.REACT_APP_API_URL;
const ApiClient = () => {
  const defaultOptions = {
    baseURL,
  };
  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use(async (request) => {
    const token = localStorage.getItem("auth");
    const stateCache = localStorage.getItem("auth_state");
    const state = JSON.parse(stateCache ?? "{}");

    if (state && DateTime.fromISO(state.expiresIn) <= DateTime.now()) {
      localStorage.removeItem("auth");
      localStorage.removeItem("auth_state");
      window.location.reload();
    }
    request.headers.Authorization = `Bearer ${token}`;
    return request;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        localStorage.removeItem("auth");
        localStorage.removeItem("auth_state");
        window.location.reload();
      }
      console.log(`error`, error);
      throw new Error(error.response.data.message);
    }
  );

  return instance;
};

export default ApiClient();
