import { Box, Flex, Heading, Text, Button } from "@chakra-ui/react";
import { ProcessViewModel } from "../../../models/Process";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";

const ProcessCard = ({ p }: { p: ProcessViewModel }) => {
  const navigate = useNavigate();

  return (
    <Box w="100%" borderRadius="8px" backgroundColor="white" p="24px">
      <Flex
        justifyContent="space-between"
        flexDir={{ base: "column", md: "row" }}
      >
        <Flex
          flexDir={{ base: "column", md: "row" }}
          gap={{ base: "12px", md: "70px" }}
        >
          <Flex
            justifyContent="space-between"
            gap={{ base: "12px", md: "70px" }}
          >
            <Flex alignItems="center" gap="6px">
              <Heading size="md">Processo {p.cvfRef}</Heading>
            </Flex>
            <Flex alignItems="center">
              <Text fontWeight="semibold" maxW="300px" size="xl">
                {p.exporter}
              </Text>
            </Flex>
          </Flex>
          {p.channel && (
            <Flex
              backgroundColor={
                p.channel?.toLowerCase() === "verde"
                  ? "#27AE60"
                  : p.channel?.toLowerCase() === "amarelo"
                  ? "#F1C40F"
                  : "#E74C3C"
              }
              h="40px"
              p="8px"
              justifyContent={{ base: "center", md: "flex-start" }}
              borderRadius="4px"
              alignItems="center"
              gap="16px"
            >
              <Text size="md" color="white">
                CANAL {p.channel.toUpperCase()}
              </Text>
            </Flex>
          )}
        </Flex>
        <Button
          display={{ base: "none", md: "block" }}
          onClick={() => navigate(`/process?id=${p.id}`)}
          backgroundColor="transparent"
          color="var(--icon-color)"
          borderWidth="2px"
          borderColor="var(--icon-color)"
        >
          VISUALIZAR DETALHES
        </Button>
      </Flex>
      <Flex
        mt="32px"
        gap="32px"
        flexDir={{ base: "column", md: "row" }}
        justifyContent="space-between"
      >
        <Box w={{ base: "400px", md: "92px" }} wordBreak="break-word">
          <Heading size="xs">Incoterm</Heading>
          <Text size="md">{p.incoterm}</Text>
        </Box>
        {/* <Box w="240px">
          <Heading size="xs">Frete</Heading>
          <Text size="md">{p.freight}</Text>
        </Box> */}

        <Flex>
          <Box w={{ base: "200px", md: "100px" }} wordBreak="break-word">
            <Heading size="xs">NCM</Heading>
            <Text size="md">{p.ncm}</Text>
          </Box>
          <Box w={{ base: undefined, md: "82px" }}>
            <Heading size="xs">Origem</Heading>
            <Text size="md">{p.countryOfOrigin}</Text>
          </Box>
        </Flex>
        <Flex>
          <Box w={{ base: "200px", md: "150px" }} wordBreak="break-word">
            <Heading size="xs">Valor</Heading>
            <Text size="md">{p.totalInvoice}</Text>
          </Box>
          <Box w={{ base: undefined, md: "150px" }}>
            <Heading size="xs">{p.di?.length === 0 ? "" : "DI"}</Heading>
            <Text size="md">{p.di}</Text>
          </Box>
        </Flex>
        <Box w={{ base: "200px", md: "260px" }} wordBreak="break-word">
          <Heading size="xs">Produto</Heading>
          <Text size="md">{p.product}</Text>
        </Box>
        <Flex>
          <Box w={{ base: "200px", md: "130px" }}>
            <Heading size="xs">Nacionalização</Heading>
            {p.nfIssued != null && (
              <Text size="md">
                {
                  DateTime.fromISO(p.nfIssued).diff(
                    DateTime.fromISO(p.arrivalDate ?? ""),
                    "days"
                  ).days
                }{" "}
                dias
              </Text>
            )}
          </Box>
          <Box>
            <Heading size="xs" w="100px">
              Transit Time
            </Heading>
            {p.arrivalDate !== null && (
              <Text size="md">
                {
                  DateTime.fromISO(p.arrivalDate ?? "").diff(
                    DateTime.fromISO(p.shippingDate ?? ""),
                    "days"
                  ).days
                }{" "}
                dias
              </Text>
            )}
          </Box>
        </Flex>
        <Button
          display={{ base: "block", md: "none" }}
          onClick={() => navigate(`/process?id=${p.id}`)}
          backgroundColor="transparent"
          color="var(--icon-color)"
          borderWidth="2px"
          borderColor="var(--icon-color)"
        >
          VISUALIZAR DETALHES
        </Button>
      </Flex>
    </Box>
  );
};

export default ProcessCard;
