import api from "./api";
import { DashboardQuery } from "../models/Dashboard";
import { EditProcessCommand } from "../models/Process";

export const getDashboard = async (filter?: DashboardQuery) => {
  try {
    const response = await api.get(`/api/process/dashboard`, {
      params: filter,
    });
    if (!response.data.success) {
      console.log("error", response.data);
    }
    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const getFinancial = async (filter?: DashboardQuery) => {
  try {
    const response = await api.get(`/api/process/financial`, {
      params: filter,
    });
    if (!response.data.success) {
      console.log("error", response.data);
    }
    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const getProcesses = async (filter?: DashboardQuery) => {
  try {
    const response = await api.get(`/api/process`, { params: filter });
    if (!response.data.success) {
      console.log("error", response.data);
    }
    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const getProcessById = async (id: string) => {
  try {
    const response = await api.get(`/api/process/${id}`);
    if (!response.data.success) {
      console.log("error", response.data);
    }
    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const saveProcess = async (process: EditProcessCommand) => {
  try {
    const response = await api.put(`/api/process/${process.id}`, process);
    if (!response.data.success) {
      console.log("error", response.data);
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
};
