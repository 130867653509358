import { Flex, Heading, Text, CloseButton, Box } from "@chakra-ui/react";
import { dashboardColors, GraphViewModel } from "../../../models/Dashboard";
import {
  AxisConfig,
  ChartsGrid,
  ChartsYAxisProps,
  PieChart,
} from "@mui/x-charts";
import { BarChart } from "@mui/x-charts/BarChart";
import { PaymentsOutlined, EmojiEventsOutlined } from "@mui/icons-material";
import { Modal } from "@mui/material";
import { ChartsXAxisProps } from "@mui/x-charts/internals";
import MapChart from "./MapChart";
import { useMediaQuery } from "react-responsive";

const GraphModal = ({
  graph,
  isOpen,
  onClose,
  formatter,
  graphType,
}: {
  graph: GraphViewModel | undefined;
  isOpen: boolean;
  onClose: () => void;
  formatter: Intl.NumberFormat;
  graphType: string;
}) => {
  const maxColor = "#19598B";
  const minColor = "#C3DFF4";
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <Modal
      style={{
        position: "fixed",
        zIndex: "1300",
        margin: "0 12px",
        inset: "0",
        display: "flex",
        alignItems: isMobile ? undefined : "center",
        justifyContent: "center",
      }}
      open={isOpen}
      onClose={onClose}
    >
      <Box borderRadius="12px" backgroundColor="white" p="24px">
        <Box>
          <Flex justifyContent="space-between">
            <Heading mb="24px" size="lg" fontWeight="normal">
              {graph?.title}
            </Heading>
            <CloseButton onClick={onClose} zIndex={100000} />
          </Flex>
        </Box>
        <Box>
          <Flex
            w="100%"
            justifyContent="space-between"
            pb="24px"
            flexDir={{ base: "column-reverse", md: "row" }}
          >
            <Flex flexDir="column">
              <Text>Maior número de importações</Text>
              <Heading mt="0" mb="24px">
                {graph?.values[0]?.label}
              </Heading>
              <Flex
                flexDir="column"
                backgroundColor="#F5F5F5"
                borderRadius="8px"
                p="32px"
                gap="25px"
              >
                <Flex gap="12px">
                  <EmojiEventsOutlined
                    style={{ color: "var(--icon-color)", fontSize: "32px" }}
                  />
                  <Flex flexDir="column">
                    <Text size="lg">Ncm mais importado:</Text>
                    <Heading size="lg" fontWeight="semibold">
                      {graph?.values[0]?.ncm}
                    </Heading>
                  </Flex>
                </Flex>
                {graph?.title === "Armador" && (
                  <Flex gap="12px" w="300px">
                    <PaymentsOutlined
                      style={{ color: "var(--icon-color)", fontSize: "32px" }}
                    />
                    <Flex flexDir="column">
                      <Text size="lg">País de origem:</Text>
                      <Heading size="lg" fontWeight="semibold">
                        {graph?.values[0]?.origin}
                      </Heading>
                    </Flex>
                  </Flex>
                )}
                <Flex gap="12px" w="300px">
                  <PaymentsOutlined
                    style={{ color: "var(--icon-color)", fontSize: "32px" }}
                  />
                  <Flex flexDir="column">
                    <Text size="lg">Total invoice:</Text>
                    <Heading size="lg" fontWeight="semibold">
                      {graphType === "1"
                        ? formatter.format(graph?.values[0]?.usd ?? 0)
                        : graph?.values[0]?.numberOfProcesses}
                    </Heading>
                  </Flex>
                </Flex>
                {graph?.title === "Origem" && (
                  <Box mt="24px">
                    <Box
                      w="100%"
                      height="24px"
                      borderRadius="12px"
                      background={`linear-gradient(to right, ${minColor}, ${maxColor})`}
                    ></Box>
                    <Flex justifyContent="space-between" mt={2}>
                      <Text>0</Text>
                      <Text>
                        {graphType === "1"
                          ? formatter
                              .format((graph?.values[0]?.usd ?? 0) / 2)
                              .replace("US$", "")
                          : (graph?.values[0]?.numberOfProcesses ?? 0) / 2}
                      </Text>
                      <Text>
                        {graphType === "1"
                          ? formatter
                              .format(graph?.values[0]?.usd ?? 0)
                              .replace("US$", "")
                          : graph?.values[0]?.numberOfProcesses ?? 0}
                      </Text>
                    </Flex>
                  </Box>
                )}
              </Flex>
            </Flex>
            <Flex>
              {graph?.title === "Armador" ? (
                <Flex
                  height="500px"
                  width={{ base: "400px", md: "700px" }}
                  ml={{ base: "0", md: "32px" }}
                  mt={{ base: "-115px", md: "0" }}
                  mr={"-48px"}
                >
                  <BarChart
                    dataset={
                      graph?.values.slice(0, 10).map((v) => ({
                        label: v.label,
                        value: graphType === "1" ? v.usd : v.numberOfProcesses,
                      })) ?? []
                    }
                    borderRadius={4}
                    yAxis={[
                      {
                        scaleType: "band",
                        dataKey: "label",
                        categoryGapRatio: 0.6,
                      } as AxisConfig<"band", any, ChartsYAxisProps>,
                    ]}
                    series={[
                      {
                        dataKey: "value",
                        valueFormatter:
                          graphType === "1"
                            ? (v) => formatter.format(v ?? 0)
                            : undefined,
                      },
                    ]}
                    layout="horizontal"
                    xAxis={[
                      {
                        label:
                          graphType === "1" ? "Invoice (USD)" : "Processos",
                      } as AxisConfig<"band", any, ChartsXAxisProps>,
                    ]}
                    width={550}
                  >
                    <ChartsGrid vertical style={{ opacity: 0.5 }} />
                  </BarChart>
                </Flex>
              ) : graph?.title === "Origem" ? (
                <Flex
                  w={{ base: "350px", md: "800px" }}
                  ml={{ base: "0", md: "32px" }}
                  mb={{ base: "32px", md: "0" }}
                >
                  <MapChart
                    {...{ isMobile, maxColor, minColor, formatter, graphType }}
                    data={graph.values}
                  />
                </Flex>
              ) : (
                <Flex
                  height="300px"
                  width={{ base: "360px", md: "400px" }}
                  mt={{ base: "24px", md: 0 }}
                  mb={{ base: "42px", md: 0 }}
                >
                  <PieChart
                    slotProps={{
                      legend: { hidden: false, seriesToDisplay: [] },
                    }}
                    colors={dashboardColors.slice(3)}
                    series={[
                      {
                        data:
                          graph?.values.map((v) => ({
                            label: v.label,
                            value:
                              graphType === "1" ? v.usd : v.numberOfProcesses,
                          })) ?? [],
                        innerRadius: 100,
                        outerRadius: 150,
                        cx: isMobile ? 180 : 200,
                      },
                    ]}
                  />
                </Flex>
              )}
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Modal>
  );
};

export default GraphModal;
