import { Flex, Heading } from "@chakra-ui/react";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faDoorOpen,
  faUserCog,
} from "@fortawesome/free-solid-svg-icons";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const HeaderMenuContent = ({ gap }: { gap?: string }) => {
  const signOut = useSignOut();
  const navigate = useNavigate();
  const auth = useAuthUser<any>();

  const handleSignOut = () => {
    signOut();
    navigate("/login");
  };
  return (
    <Flex padding="12px" flexDirection="column" width="100%" gap="4px">
      <Flex
        alignItems="center"
        gap={gap ?? "12px"}
        padding={"12px"}
        onClick={() => navigate("/account")}
        cursor="pointer"
        _hover={{ backgroundColor: "var(--hover-color)" }}
      >
        <FontAwesomeIcon icon={faUserCog} color="var(--icon-color)" />
        <Heading cursor="pointer" size="sm" fontWeight="normal">
          Gerenciar conta
        </Heading>
      </Flex>
      {auth?.admin && (
        <Flex
          padding={"12px"}
          alignItems="center"
          gap={gap ?? "12px"}
          cursor="pointer"
          onClick={() => navigate("/configurations")}
          _hover={{ backgroundColor: "var(--hover-color)" }}
        >
          <FontAwesomeIcon
            width="18px"
            icon={faCog}
            color="var(--icon-color)"
          />
          <Heading cursor="pointer" size="sm" fontWeight="normal">
            Configurações
          </Heading>
        </Flex>
      )}
      <hr />
      <Flex
        alignItems="center"
        gap={gap ?? "12px"}
        cursor="pointer"
        padding={"12px"}
        _hover={{ backgroundColor: "var(--hover-color)" }}
        onClick={handleSignOut}
      >
        <FontAwesomeIcon
          width="18px"
          icon={faDoorOpen}
          color="var(--icon-color)"
        />
        <Heading size="sm" fontWeight="normal">
          Sair
        </Heading>
      </Flex>
    </Flex>
  );
};

export default HeaderMenuContent;
