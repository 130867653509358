export const countries = {
  portuguese: [
    { id: 1, name: "China" },
    { id: 2, name: "Alemanha" },
    { id: 3, name: "Arábia Saudita" },
    { id: 4, name: "Bélgica" },
    { id: 5, name: "Brasil" },
    { id: 6, name: "Canadá" },
    { id: 7, name: "Chile" },
    { id: 8, name: "Colômbia" },
    { id: 9, name: "Coreia" },
    { id: 10, name: "Coreia do Sul" },
    { id: 11, name: "Dinamarca" },
    { id: 12, name: "Eslováquia" },
    { id: 13, name: "Eslovênia" },
    { id: 14, name: "Espanha" },
    { id: 15, name: "Estados Unidos" },
    { id: 16, name: "Finlândia" },
    { id: 17, name: "Holanda" },
    { id: 18, name: "Índia" },
    { id: 19, name: "Indonésia" },
    { id: 20, name: "Istambul" },
    { id: 21, name: "Itália" },
    { id: 22, name: "Japão" },
    { id: 23, name: "México" },
    { id: 24, name: "Omã" },
    { id: 25, name: "Paquistão" },
    { id: 26, name: "Polônia" },
    { id: 27, name: "Portugal" },
    { id: 28, name: "Reino Unido" },
    { id: 29, name: "República Tcheca" },
    { id: 30, name: "Sri Lanka" },
    { id: 31, name: "Suécia" },
    { id: 32, name: "Suíça" },
    { id: 33, name: "Tailândia" },
    { id: 34, name: "Taiwan" },
    { id: 35, name: "Turquia" },
    { id: 36, name: "Ucrânia" },
    { id: 37, name: "Venezuela" },
    { id: 38, name: "Malásia" },
  ],
  english: [
    { id: 1, name: "China" },
    { id: 2, name: "Germany" },
    { id: 3, name: "Saudi Arabia" },
    { id: 4, name: "Belgium" },
    { id: 5, name: "Brazil" },
    { id: 6, name: "Canada" },
    { id: 7, name: "Chile" },
    { id: 8, name: "Colombia" },
    { id: 9, name: "Korea" },
    { id: 10, name: "South Korea" },
    { id: 11, name: "Denmark" },
    { id: 12, name: "Slovakia" },
    { id: 13, name: "Slovenia" },
    { id: 14, name: "Spain" },
    { id: 15, name: "United States" },
    { id: 16, name: "Finland" },
    { id: 17, name: "Netherlands" },
    { id: 18, name: "India" },
    { id: 19, name: "Indonesia" },
    { id: 20, name: "Istanbul" },
    { id: 21, name: "Italy" },
    { id: 22, name: "Japan" },
    { id: 23, name: "Mexico" },
    { id: 24, name: "Oman" },
    { id: 25, name: "Pakistan" },
    { id: 26, name: "Poland" },
    { id: 27, name: "Portugal" },
    { id: 28, name: "United Kingdom" },
    { id: 29, name: "Czech Republic" },
    { id: 30, name: "Sri Lanka" },
    { id: 31, name: "Sweden" },
    { id: 32, name: "Switzerland" },
    { id: 33, name: "Thailand" },
    { id: 34, name: "Taiwan" },
    { id: 35, name: "Turkey" },
    { id: 36, name: "Ukraine" },
    { id: 37, name: "Venezuela" },
  ],
};
