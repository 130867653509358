import { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  IconButton,
  useDisclosure,
  Button,
  Text,
  Link,
} from "@chakra-ui/react";
import TableWithPagination from "../../components/TableWithPagination";
import Header from "../../components/Header/Header";
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { downloadFile, getDocuments } from "../../services/documentService";
import Filter from "../../components/Filter";
import { DateFilter, Tag } from "../../models/Filter";
import { DashboardQuery } from "../../models/Dashboard";
import { useNavigate, useLocation } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import FilterModal from "../../components/FilterModal";

const Documents = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [loadingFile, setLoadingFile] = useState(false);
  const [filter, setFilter] = useState<DashboardQuery>();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [tags, setTags] = useState<Tag[]>([]);
  const [dateFilter] = useState<DateFilter>();
  const [page, setPage] = useState<any[]>([]);
  const [filteredPage, setFilteredPage] = useState<any[]>([]);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const download = async (p: any) => {
    setLoadingFile(true);
    var file = await downloadFile(p.path);
    const linkSource = `data:application/pdf;base64,${file}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = `${p.cvfRef} - ${p.name}`;
    downloadLink.click();
    setLoadingFile(false);
  };

  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");

  const get = async (
    localPageSize?: number,
    localPageNumber?: number,
    localDateFilter?: DateFilter
  ) => {
    const localPs = localPageSize ?? pageSize;
    const localPn = localPageNumber ?? pageIndex;
    const localDf = localDateFilter ?? dateFilter;

    const response = await getDocuments({
      processId: id ?? undefined,
      pageSize: id ? 10000 : localPs,
      pageNumber: localPn,
      dateType: localDf?.dateType,
      initialDate: localDf?.initialDate?.toISO() ?? "",
      finalDate: localDf?.finalDate?.toISO() ?? "",
      ncm: tags
        .filter((t) => t.type === "ncm")
        ?.map((t) => t.value)
        .join("|"),
      departurePort: tags
        .filter((t) => t.type === "departurePort")
        ?.map((t) => t.value)
        .join("|"),
      cvfRef: tags
        .filter((t) => t.type === "cvfRef")
        ?.map((t) => t.value)
        .join("|"),
      dischargePort: tags
        .filter((t) => t.type === "dischargePort")
        ?.map((t) => t.value)
        .join("|"),
      companyId: tags
        .filter((t) => t.type === "companyId")
        ?.map((t) => t.value)
        .join("|"),
      countryOfOrigin: tags
        .filter((t) => t.type === "origin")
        ?.map((t) => t.value)
        .join("|"),
      exporter: tags
        .filter((t) => t.type === "supplier")
        ?.map((t) => t.value)
        .join("|"),
      name: tags
        .filter((t) => t.type === "name")
        ?.map((t) => t.value)
        .join("|"),
    });
    setPage(response.data);
    setFilteredPage(response.data);
    return response;
  };
  useEffect(() => {
    get();
  }, [tags]);

  return (
    <Box height="100%">
      <Header />
      <Flex
        alignItems="center"
        flexDirection="column"
        backgroundColor="var(--main-bg-color)"
      >
        {id && (
          <Flex
            alignItems="center"
            gap="8px"
            width="90%"
            mt={{ base: "16px", md: "27px" }}
          >
            <Link
              color="var(--icon-color)"
              onClick={() => navigate("/processes")}
              size="md"
            >
              Processos
            </Link>
            <FaAngleRight />
            <Link
              color="var(--icon-color)"
              onClick={() => navigate(`/process?id=${id}`)}
              size="md"
            >
              Detalhes
            </Link>
            <FaAngleRight />
            <Text size="md">Documentos</Text>
          </Flex>
        )}
        <Flex width="90%" mt="48px">
          <Heading size="lg" mb="24px" fontWeight="normal">
            Documentos{id && page[0] ? ` do processo ${page[0].cvfRef}` : ""}
          </Heading>
        </Flex>
        <Flex
          backgroundColor="var(--filter-color)"
          borderRadius="8px"
          width="90%"
          paddingBottom="32px"
          mb="48px"
        >
          <FilterModal
            types={[
              { label: "Nº do processo", type: "cvfRef" },
              { label: "Pesquisar por nome do arquivo", type: "name" },
            ]}
            {...{
              isOpen,
              onClose,
              tags,
              setTags,
            }}
          />
          {isMobile ? (
            <Flex w="100%" justifyContent="center" mt="32px">
              <Button
                w="80%"
                backgroundColor="var(--icon-color)"
                color="white"
                onClick={onOpen}
              >
                Filtrar documentos
              </Button>
            </Flex>
          ) : (
            <Box pl="32px" pt="32px" borderRadius="8px">
              <Filter
                types={[
                  { label: "Nº do processo", type: "cvfRef" },
                  { label: "Pesquisar por nome do arquivo", type: "name" },
                ]}
                {...{
                  tags,
                  setTags,
                }}
              />
            </Box>
          )}
        </Flex>
        <Flex
          width={isMobile ? "95%" : "90%"}
          height="100%"
          backgroundColor="white"
          borderRadius="8px"
          mb="32px"
          flexDirection="column"
        >
          <Box p={isMobile ? 0 : "32px"} pt="32px">
            <TableWithPagination
              columns={
                id || isMobile
                  ? [
                      {
                        label: "Nome do documento",
                        data: (p: any) => (
                          <Text
                            overflow="hidden"
                            maxWidth={isMobile ? "30vh" : undefined}
                            size="md"
                          >
                            {isMobile ? `${p.cvfRef} - ` : ""} {p.name}
                          </Text>
                        ),
                      },
                      {
                        label: "",
                        data: (p: any) => (
                          <IconButton
                            onClick={() => download(p)}
                            aria-label="Download"
                            backgroundColor="white"
                            icon={<FontAwesomeIcon icon={faDownload} />}
                          />
                        ),
                      },
                    ]
                  : [
                      {
                        label: "Nº Processo",
                        width: "100px",
                        data: (p: any) => <Text size="md">{p.cvfRef}</Text>,
                      },
                      {
                        label: "Nome do documento",
                        data: (p: any) => <Text size="md">{p.name}</Text>,
                      },
                      {
                        label: "",
                        data: (p: any) => (
                          <IconButton
                            isLoading={loadingFile}
                            onClick={() => download(p)}
                            backgroundColor="white"
                            aria-label="Download"
                            borderWidth="1px"
                            borderColor="#EEEEEE"
                            icon={<FontAwesomeIcon icon={faDownload} />}
                          />
                        ),
                      },
                    ]
              }
              page={filteredPage}
              setPage={setFilteredPage}
              {...{
                filter,
                setFilter,
                tags,
                get,
                pageIndex,
                setPageIndex,
                pageSize,
                setPageSize,
              }}
              emptyMessage="Não há documentos ainda"
            />
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Documents;
