import {
  CloseButton,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { ProcessViewModel } from "../../../models/Process";
import { DateTime } from "luxon";

const FinancialModal = ({
  p,
  isOpen,
  onClose,
}: {
  p: ProcessViewModel;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const formatterUsd = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "USD",
  });
  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent fontFamily="Inter">
        <ModalHeader>
          <Flex justifyContent="space-between">
            <Heading size="lg" fontWeight="semibold">
              Processo {p?.cvfRef}
            </Heading>
            <CloseButton onClick={onClose} />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Flex flexDir="column">
            <Flex
              flexDir="column"
              gap="16px"
              backgroundColor="white"
              p="32px 16px"
            >
              <Flex>
                <Flex
                  flexDir="column"
                  gap="8px"
                  w={{ base: "200px", md: "300px" }}
                >
                  <Heading size="sm" fontWeight="semibold">
                    Termo de pagamento:
                  </Heading>

                  <Heading fontWeight="normal" size="md">
                    {p?.condition}
                  </Heading>
                </Flex>
                <Flex flexDir="column" gap="8px">
                  <Heading size="sm" fontWeight="semibold">
                    Total pago:
                  </Heading>

                  <Heading fontWeight="normal" size="md" color="red.600">
                    {formatterUsd.format(parseFloat(p?.totalPayed))}
                  </Heading>
                </Flex>
              </Flex>
              <Flex>
                <Flex
                  flexDir="column"
                  gap="8px"
                  w={{ base: "200px", md: "300px" }}
                >
                  <Heading size="sm" fontWeight="semibold">
                    Total a pagar:
                  </Heading>

                  <Heading size="md" color="green.600" fontWeight="normal">
                    {formatterUsd.format(parseFloat(p?.totalToPay))}
                  </Heading>
                </Flex>
                <Flex flexDir="column" gap="8px">
                  <Heading size="sm" fontWeight="semibold">
                    Total do processo:
                  </Heading>
                  <Heading size="md" fontWeight="normal">
                    {p?.totalInvoice}
                  </Heading>
                </Flex>
              </Flex>
            </Flex>
            <Flex flexDirection="column" gap="16px" p="16px">
              {p?.financialItems
                .filter((f) => (f.valorPagamento?.length ?? 0) > 1)
                .map((f, index) => (
                  <>
                    <Flex>
                      <Flex
                        flexDir="column"
                        gap="8px"
                        w={{ base: "200px", md: "300px" }}
                      >
                        <Heading size="sm" fontWeight="semibold">
                          Taxa de câmbio ({index === 0 ? "Sinal" : "Saldo"})
                        </Heading>
                        <Flex flexDir="column" gap="16px">
                          <Heading size="md" fontWeight="normal">
                            {(f.taxaCambio?.length ?? 0) > 0
                              ? f.taxaCambio
                              : "Por definir"}
                          </Heading>
                        </Flex>
                      </Flex>
                      <Flex flexDir="column" gap="8px">
                        <Heading size="sm" fontWeight="semibold">
                          Data de câmbio
                        </Heading>
                        <Flex flexDir="column" gap="16px">
                          <Heading size="md" fontWeight="normal">
                            {f.dataCambio
                              ? DateTime.fromISO(f.dataCambio).toFormat(
                                  "dd/LL/yyyy"
                                )
                              : f.dataPagamento
                              ? DateTime.fromISO(f.dataPagamento).toFormat(
                                  "dd/LL/yyyy"
                                )
                              : "Por definir"}
                          </Heading>
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex>
                      <Flex
                        flexDir="column"
                        gap="8px"
                        w={{ base: "200px", md: "300px" }}
                      >
                        <Heading size="sm" fontWeight="semibold">
                          {index === 0 ? "Sinal" : "Saldo"} de pagamento
                        </Heading>
                        <Flex flexDir="column" gap="16px">
                          <Heading size="md" fontWeight="normal">
                            {f.valorPagamento}
                          </Heading>
                        </Flex>
                      </Flex>
                      <Flex flexDir="column" gap="8px">
                        <Heading size="sm" fontWeight="semibold">
                          Status
                        </Heading>
                        <Flex flexDir="column" gap="16px">
                          <Heading
                            size="sm"
                            fontWeight="normal"
                            borderWidth="2px"
                            p="12px"
                            backgroundColor={
                              f.dataPagamento ? "#F1FCF5" : "#F2F8FD"
                            }
                            borderColor={
                              f.dataPagamento ? "#8BEAB2" : "#8FC5EA"
                            }
                            color={f.dataPagamento ? "#27AE60" : "#2980B9"}
                          >
                            {f.dataPagamento ? "PAGO" : "PROGRAMADO"}
                          </Heading>
                        </Flex>
                      </Flex>
                    </Flex>
                  </>
                ))}
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default FinancialModal;
