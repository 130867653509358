import { Table, Thead, Tbody, Tr, Th, Text, Td } from "@chakra-ui/react";
import { GraphValueViewModel } from "../../../models/Dashboard";

const SupliersTable = ({
  graphType,
  page,
  formatter,
}: {
  graphType: string;
  page: GraphValueViewModel[];
  formatter: Intl.NumberFormat;
}) => {
  const columns: { label: string; data: (row: GraphValueViewModel) => any }[] =
    [
      { label: "Armador", data: (row) => row.label },
      { label: "NCM", data: (row) => row.ncm },
      {
        label: "Total importado",
        data: (row) =>
          graphType === "1"
            ? formatter.format(row.usd)
            : `${row.numberOfProcesses} processos`,
      },
    ];
  return (
    <Table fontFamily="Inter" size="lg" w="100%">
      <Thead>
        <Tr>
          {columns.map((column) => (
            <Th key={column.label}>{column.label}</Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {page.map((row, index) => (
          <Tr key={index}>
            {columns.map((column) => (
              <Td key={column.label}>{column.data(row)}</Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default SupliersTable;
