import { Flex, Heading, IconButton, Input, Text } from "@chakra-ui/react";
import {
  faCheck,
  faClose,
  faPencil,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { saveProcess } from "../../../services/processService";
import { FieldValue, FieldValues, UseFormRegister } from "react-hook-form";

const Prop = ({
  title,
  value,
  process,
  name,
  outEditing,
  get,
  register,
  isMobile,
}: {
  isMobile: boolean;
  title: string;
  value: string | undefined;
  process: any;
  get: any;
  name: string;
  outEditing: boolean;
  register: UseFormRegister<FieldValues>;
}) => {
  const auth = useAuthUser<any>();
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const save = async () => {
    const response = await saveProcess({ ...process, [name]: inputValue });
    if (response) {
      get();
      setEditing(false);
    }
  };

  useEffect(() => {
    setEditing(outEditing);
  }, [outEditing]);

  return (
    <Flex
      gap="8px"
      flexDir="column"
    >
      {(outEditing || (value?.length ?? 0) > 0) && (
        <>
          <Heading size="md" fontWeight="bold">
            {title}
          </Heading>{" "}
          {editing ? (
            <Flex>
              <Input
                defaultValue={value}
                value={inputValue}
                width="auto"
                variant="flushed"
                {...register(name, {
                  onChange: (e: any) => setInputValue(e.target.value),
                })}
              />
              {!outEditing && (
                <Flex>
                  <IconButton
                    onClick={() => save()}
                    aria-label="Salvar"
                    icon={<FontAwesomeIcon icon={faCheck} />}
                  />
                  <IconButton
                    aria-label="Salvar"
                    onClick={() => setEditing(false)}
                    icon={<FontAwesomeIcon icon={faClose} />}
                  />
                </Flex>
              )}
            </Flex>
          ) : (
            <Flex alignItems="center" gap="16px">
              <Text fontSize={18}>{value}</Text>
              {/* {auth?.admin && (
                <FontAwesomeIcon
                  cursor="pointer"
                  onClick={() => setEditing(true)}
                  icon={faPencil}
                />
              )} */}
            </Flex>
          )}
        </>
      )}
    </Flex>
  );
};

export default Prop;
