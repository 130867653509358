import {
  Box,
  Flex,
  Heading,
  IconButton,
  Input,
  CloseButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Button,
  Text,
  InputGroup,
  InputRightElement,
  Link,
  ModalHeader,
  ModalFooter,
} from "@chakra-ui/react";
import Filter from "./Filter";
import { Tag } from "../models/Filter";
import { Dispatch, SetStateAction } from "react";
import { FaSearch } from "react-icons/fa";

const FilterModal = ({
  isOpen,
  onClose,
  tags,
  setTags,
  extraFilters,
  types,
}: {
  isOpen: boolean;
  onClose: () => void;
  tags: Tag[];
  setTags: Dispatch<SetStateAction<Tag[]>>;
  extraFilters?: any[];
  types?: { label: string; type: string }[];
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex justifyContent="flex-end">
            <CloseButton onClick={onClose} />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Box p="16px" pt="0">
            <Filter
              {...{
                types,
                tags,
                setTags,
                extraFilters,
              }}
            />
          </Box>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button
            w="90%"
            backgroundColor="var(--icon-color)"
            color="white"
            onClick={onClose}
            mb="64px"
          >
            Filtrar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default FilterModal;
