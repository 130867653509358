import {
  ChangePasswordCommand,
  EditUserCommand,
  GenerateSignUpTokenCommand,
  SignUpCommand,
} from "../models/User";
import api from "./api";

export const login = async (login: { email: string; password: string }) => {
  try {
    const response = await api.post("/api/User/Login", login);
    if (!response.data.success) {
      console.log("error", response.data.notifications);
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const signUp = async (user: SignUpCommand) => {
  try {
    const response = await api.post(`/api/user/signUp`, user);
    if (!response.data.success) {
      console.log("error", response.data.notifications);
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const generateSignUpToken = async (user: GenerateSignUpTokenCommand) => {
  try {
    const response = await api.post(`/api/user/signUpToken`, user);
    if (!response.data.success) {
      console.log("error", response.data.notifications);
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const updateUser = async (user: EditUserCommand) => {
  try {
    const response = await api.put(`/api/user/${user.id}`, user);
    if (!response.data.success) {
      console.log("error", response.data.notifications);
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const emulate = async (user: EditUserCommand) => {
  try {
    const response = await api.put(`/api/user/${user.id}/emulate`, user);
    if (!response.data.success) {
      console.log("error", response.data.notifications);
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const forgotPassword = async (email: string) => {
  try {
    const response = await api.post(`/api/user/forgotPassword`, { email });
    if (!response.data.success) {
      console.log("error", response.data.notifications);
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const changePassword = async (changePassword: ChangePasswordCommand) => {
  try {
    const response = await api.put(`/api/user/changePassword`, changePassword);
    if (!response.data.success) {
      console.log("error", response.data.notifications);
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const getUser = async (id?: string) => {
  try {
    const response = await api.get(`/api/user/${id}`);
    if (!response.data.success) {
      console.log("error", response.data);
    }
    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const getPermissionsByToken = async (token?: string) => {
  try {
    const response = await api.get(`/api/user/${token}/permissions`);
    if (!response.data.success) {
      console.log("error", response.data);
    }
    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const deleteUser = async (id: string | undefined) => {
  try {
    const response = await api.delete(`/api/user/${id}`);
    if (!response.data.success) {
      console.error(response.data);
    }
    return response;
  } catch (error) {
    console.error("error", error);
  }
};
