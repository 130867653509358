import {
  Box,
  Flex,
  Heading,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import Header from "../../components/Header/Header";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { getProcessById, saveProcess } from "../../services/processService";
import { ProcessItemViewModel, ProcessViewModel } from "../../models/Process";
import { useMediaQuery } from "react-responsive";
import { DateTime } from "luxon";
import { Event, EventAvailable } from "@mui/icons-material";
import Prop from "./components/Prop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileContract } from "@fortawesome/free-solid-svg-icons";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { RiShip2Fill } from "react-icons/ri";
import { FaAngleRight, FaRegFileAlt } from "react-icons/fa";

const Process = () => {
  const { register, handleSubmit, formState, getValues, reset } = useForm();
  const [outEditing, setOutEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [process, setProcess] = useState<ProcessViewModel>();

  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const navigate = useNavigate();
  const auth = useAuthUser<any>();

  const save = async (data: any) => {
    setLoading(true);
    const response = await saveProcess({
      ...data,
      shippingDate: DateTime.fromFormat(data.shippingDate, "dd/LL/yyyy"),
      id,
    });
    if (response) {
      get();
      setOutEditing(false);
      setLoading(false);
    }
  };

  const get = async () => {
    if (id) {
      const response = await getProcessById(id);
      if (response) setProcess(response);
    }
  };

  useEffect(() => {
    get();
  }, [id]);
  const values = [
    {
      title: "Data de Registro:",
      name: "registrationDate",
      value: DateTime.fromISO(process?.registrationDate ?? "").isValid
        ? DateTime.fromISO(process?.registrationDate ?? "")?.toFormat(
            "dd/LL/yyyy"
          )
        : undefined,
    },
    {
      title: "Frete:",
      value: process?.freight,
      name: "freight",
    },
    {
      title: "Incoterm:",
      value: process?.incoterm,
      name: "incoterm",
    },
    {
      title: "Peso Líq.:",
      value: process?.weight,
      name: "weight",
    },
    {
      title: "Agente marítimo:",
      value: process?.customsAgent,
      name: "customsAgent",
    },
    { title: "Fatura:", value: process?.invoice, name: "invoice" },
    { title: "LI:", value: process?.li, name: "li" },
    { title: "Validade:", value: process?.validity, name: "validity" },
    {
      title: "Documento de Envio:",
      value: process?.shippingDocument,
      name: "shippingDocument",
    },
    { title: "Embarcação:", value: process?.vessel, name: "vessel" },
    {
      title: "Porto de Descarga:",
      value: process?.dischargePort,
      name: "dischargePort",
    },
    { title: "DI:", value: process?.di, name: "di" },
    { title: "BL/AWB/CRT Nº:", value: process?.blAwbCrt, name: "blAwbCrt" },
    { title: "Demurrage:", value: process?.demurrage, name: "demurrage" },
    { title: "Transportadora", value: process?.carrier, name: "carrier" },
  ];

  const columns = [
    {
      label: "NCM",
      data: (p: ProcessItemViewModel) => <Text>{p?.ncm}</Text>,
    },
    {
      label: "Produto",
      data: (p: ProcessItemViewModel) => <Text>{p?.product}</Text>,
    },
    {
      label: "Quantidade",
      data: (p: ProcessItemViewModel) => <Text>{p?.quantity}</Text>,
    },
    {
      label: "Valor unitário",
      data: (p: ProcessItemViewModel) => <Text>{p?.unitValue}</Text>,
    },
  ];
  return (
    <Box height="100%">
      <Header />
      <Flex
        backgroundColor="var(--main-bg-color)"
        overflowY="scroll"
        flexDirection="column"
        pt="0"
        alignItems="center"
      >
        <Flex flexDir="column" mb="100px" width={isMobile ? "90%" : "90%"}>
          <Flex
            alignItems="center"
            mb="48px"
            pt="0"
            gap="8px"
            mt={{ base: "16px", md: "27px" }}
            width="90%"
          >
            <Link
              color="var(--icon-color)"
              onClick={() => navigate("/processes")}
              size="md"
            >
              Processos
            </Link>
            <FaAngleRight />
            <Text size="md">Detalhes</Text>
          </Flex>
          <Flex>
            <Heading size="lg" fontWeight="normal" mb="32px">
              Detalhes do Processo {process?.cvfRef}
            </Heading>
          </Flex>
          <Flex gap="12px" w="100%" flexDir={{ base: "column", md: "row" }}>
            <Flex className="dashboard-card" w="100%">
              <Heading size="md" fontWeight="normal">
                Total invoice
              </Heading>
              <Heading size="xl" mt="16px">
                {process?.totalInvoice}
              </Heading>
            </Flex>
            <Flex
              className="dashboard-card"
              w="100%"
              gap="8px"
              alignItems="center"
              cursor="pointer"
              _hover={{ backgroundColor: "var(--hover-color)" }}
              onClick={() => navigate(`/tracking?id=${id}`)}
            >
              <RiShip2Fill color="var(--icon-color)" fontSize="32px" />
              <Heading size="lg" fontWeight="normal" textAlign="center">
                Rastrear
              </Heading>
            </Flex>
            <Flex
              className="dashboard-card"
              w="100%"
              gap="8px"
              cursor="pointer"
              alignItems="center"
              _hover={{ backgroundColor: "var(--hover-color)" }}
              onClick={() => navigate(`/documents?id=${id}`)}
            >
              <FaRegFileAlt color="var(--icon-color)" fontSize="32px" />
              <Heading size="lg" fontWeight="normal" textAlign="center">
                Ver documentos
              </Heading>
            </Flex>
          </Flex>
          <Flex
            flexWrap="wrap"
            gap="1.5vh"
            backgroundColor="white"
            borderRadius="8px"
            padding="32px"
            width="100%"
            mt="24px"
          >
            <VStack alignItems="flex-start" gap="64px">
              <Flex
                flexDir={{ base: "column", md: "row" }}
                gap={{ base: "32px", md: "120px" }}
              >
                {DateTime.fromISO(process?.shippingDate ?? "").isValid && (
                  <Flex alignItems="center" gap="15px">
                    <Event color="primary" fontSize="large" />{" "}
                    <Heading size="lg" fontWeight="normal">
                      {DateTime.fromISO(process?.shippingDate ?? "")?.toFormat(
                        "dd/LL/yyyy"
                      )}
                    </Heading>
                  </Flex>
                )}
                {DateTime.fromISO(process?.arrivalDate ?? "").isValid && (
                  <Flex alignItems="center" gap="15px">
                    <EventAvailable color="primary" fontSize="large" />{" "}
                    <Heading size="lg" fontWeight="normal">
                      {DateTime.fromISO(process?.arrivalDate ?? "")?.toFormat(
                        "dd/LL/yyyy"
                      )}
                    </Heading>
                  </Flex>
                )}
                {process?.channel && (
                  <Flex
                    alignItems="center"
                    gap="15px"
                    backgroundColor={
                      process?.channel?.toLowerCase() === "verde"
                        ? "#27AE60"
                        : process?.channel?.toLowerCase() === "amarelo"
                        ? "#F1C40F"
                        : "#E74C3C"
                    }
                    color="white"
                    p="8px"
                  >
                    <Heading size="md" fontWeight="normal">
                      CANAL {process?.channel?.toUpperCase()}
                    </Heading>
                  </Flex>
                )}
                <Flex
                  alignItems="center"
                  gap="15px"
                  backgroundColor="var(--icon-color)"
                  color="white"
                  p="8px"
                >
                  <Heading size="md" fontWeight="normal">
                    {process?.importMode}
                  </Heading>
                </Flex>
              </Flex>
              <Flex
                flexDir={{ base: "column", md: "row" }}
                flexWrap="wrap"
                gap="48px"
                backgroundColor="white"
              >
                {values
                  .filter((v) => (v.value?.length ?? 0) > 0)
                  .map((v) => (
                    <Prop
                      isMobile={isMobile}
                      get={get}
                      register={register}
                      title={v.title}
                      value={v.value}
                      name={v.name}
                      process={process}
                      outEditing={outEditing}
                    />
                  ))}
                <Prop
                  isMobile={isMobile}
                  get={get}
                  register={register}
                  title={"Exportador:"}
                  value={process?.exporter}
                  name={"exporter"}
                  process={process}
                  outEditing={outEditing}
                />
                <Prop
                  isMobile={isMobile}
                  get={get}
                  register={register}
                  title={"Nº do Container:"}
                  value={process?.containerNumber}
                  name={"exporter"}
                  process={process}
                  outEditing={outEditing}
                />
              </Flex>
            </VStack>
          </Flex>
          <Flex
            flexWrap="wrap"
            gap="1.5vh"
            backgroundColor="white"
            borderRadius="8px"
            padding="32px"
            width={{ base: "100%", md: "55%" }}
            mb="32px"
            mt="24px"
          >
            <VStack>
              <Flex flexDirection="column">
                <Heading size="md" marginBottom="16px">
                  Itens do processo:
                </Heading>
                <Flex
                  width={isMobile ? "35vh" : undefined}
                  overflowX={isMobile ? "scroll" : undefined}
                >
                  <Table>
                    <Thead>
                      <Tr>
                        {columns.map((column) => (
                          <Th key={column.label}>{column.label}</Th>
                        ))}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {process?.items?.map((row) => (
                        <Tr key={row.id}>
                          {columns.map((column) => (
                            <Td key={column.label}>{column.data(row)}</Td>
                          ))}
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Flex>
              </Flex>
            </VStack>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Process;
