import { DashboardQuery } from "../models/Dashboard";
import api from "./api";

export const getDocuments = async (filter?: DashboardQuery) => {
  try {
    const response = await api.get(`/api/document`, { params: filter });
    if (!response.data.success) {
      console.log("error", response.data);
    }
    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const downloadFile = async (path: string) => {
  try {
    const response = await api.get(`/api/document/download?path=${path}`);
    if (!response.data.success) {
      console.log("error", response.data);
    }
    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};
