import { useEffect, useState } from "react";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import Migration from "./tabs/Migration/Migration";
import AddUsers from "./tabs/AddUsers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import {
  faDatabase,
  faKey,
  faUser,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import Header from "../../components/Header/Header";
import { useMediaQuery } from "react-responsive";
import { FaAngleLeft, FaUserAlt } from "react-icons/fa";
import Emulation from "./tabs/Emulation";
import Permissions from "./tabs/Permissions";
import CompanyPermissions from "./tabs/CompanyPermissions";

const Configurations = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const auth = useAuthUser<any>();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(isMobile ? undefined : 0);
  const [tabs, setTabs] = useState([
    {
      label: "Adicionar usuário",
      icon: <FontAwesomeIcon icon={faUserPlus} fontSize={isMobile ? 18 : 24} />,
      content: <AddUsers />,
    },
    {
      label: "Gerir permissões",
      icon: <FontAwesomeIcon icon={faKey} fontSize={isMobile ? 18 : 24} />,
      content: <Permissions />,
    },
    {
      label: "Gerir permissões das empresas",
      icon: (
        <Flex>
          <FontAwesomeIcon icon={faKey} fontSize={isMobile ? 18 : 24} />
          <FontAwesomeIcon icon={faUser} fontSize={isMobile ? 18 : 24} />
        </Flex>
      ),
      content: <CompanyPermissions />,
    },
    {
      label: "Emular clientes",
      icon: <FaUserAlt fontSize={isMobile ? 18 : 24} />,
      content: <Emulation />,
    },
  ]);

  useEffect(() => {
    if (!auth?.admin) navigate("/");
  }, [auth]);

  return (
    <Box overflowX="hidden">
      <Header />
      <Flex
        flexDirection="column"
        alignItems="center"
        backgroundColor="var(--main-bg-color)"
      >
        <Flex width={{ base: "90%", md: "50%" }} flexDir="column">
          <AddUsers />
          <Emulation />
          <Permissions />
          <CompanyPermissions />
        </Flex>
      </Flex>
    </Box>
  );
};
export default Configurations;
