import { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  CloseButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import TableWithPagination from "../../components/TableWithPagination";
import Header from "../../components/Header/Header";
import { getProcesses } from "../../services/processService";
import { Tag } from "../../models/Filter";
import Filter from "../../components/Filter";
import { useMediaQuery } from "react-responsive";
import ProcessCard from "./components/ProcessCard";
import FilterModal from "../../components/FilterModal";

const Processes = () => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [tags, setTags] = useState<Tag[]>([]);
  const [page, setPage] = useState<any[]>([]);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const get = async (localPageSize?: number, localPageNumber?: number) => {
    const localPs = localPageSize ?? pageSize;
    const localPn = localPageNumber ?? pageIndex;

    const response = await getProcesses({
      pageSize: localPs,
      pageNumber: localPn,
      ncm: tags
        .filter((t) => t.type === "ncm")
        ?.map((t) => t.value)
        .join("|"),
      departurePort: tags
        .filter((t) => t.type === "departurePort")
        ?.map((t) => t.value)
        .join("|"),
      cvfRef: tags
        .filter((t) => t.type === "cvfRef")
        ?.map((t) => t.value)
        .join("|"),
      dischargePort: tags
        .filter((t) => t.type === "dischargePort")
        ?.map((t) => t.value)
        .join("|"),
      companyId: tags
        .filter((t) => t.type === "companyId")
        ?.map((t) => t.value)
        .join("|"),
      countryOfOrigin: tags
        .filter((t) => t.type === "origin")
        ?.map((t) => t.value)
        .join("|"),
      exporter: tags
        .filter((t) => t.type === "supplier")
        ?.map((t) => t.value)
        .join("|"),
    });
    setPage(response.data);
    return response;
  };
  useEffect(() => {
    get();
  }, [tags]);

  return (
    <Box height="100%" overflowX="hidden">
      <Header />
      <Flex
        height="100%"
        flexDirection="column"
        alignItems="center"
        backgroundColor="var(--main-bg-color)"
      >
        <Flex width="90%">
          <Heading size="lg" mt="48px" mb="24px" fontWeight="normal">
            Processos
          </Heading>
        </Flex>
        <FilterModal
          {...{
            isOpen,
            onClose,
            tags,
            setTags,
          }}
        />
        <Box
          p="32px"
          w="90%"
          backgroundColor="var(--filter-color)"
          borderRadius="8px"
        >
          {isMobile ? (
            <Flex w="100%" justifyContent="center">
              <Button
                w="80%"
                backgroundColor="var(--icon-color)"
                color="white"
                onClick={onOpen}
              >
                Filtrar documentos
              </Button>
            </Flex>
          ) : (
            <Flex width="90%">
              <Filter
                {...{
                  tags,
                  setTags,
                }}
              />
            </Flex>
          )}
        </Box>

        <Flex width="90%" flexDir="column" mt="64px" gap="24px">
          {page.map((p) => (
            <ProcessCard {...{ p }} />
          ))}
        </Flex>
        <Flex width="90%" my="32px">
          <TableWithPagination
            hideTable
            columns={[]}
            {...{
              tags,
              page,
              setPage,
              get,
              pageIndex,
              setPageIndex,
              pageSize,
              setPageSize,
            }}
            emptyMessage="Não há processos ainda"
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default Processes;
