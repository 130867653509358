import { SaveTrackingCommand, UpdateTrackingCommand } from "../models/Tracking";
import api from "./api";

export const getShipsGoByProcessId = async (id: string) => {
  try {
    const response = await api.get(`/api/ShipsGo/${id}`);
    if (!response.data.success) {
      console.log("error", response.data);
    }
    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};
