import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";
import { Tag } from "../models/Filter";
import GeneralTagFilter from "./GeneralTagFilter";
import { useMediaQuery } from "react-responsive";
import TagFilter from "./TagFilter";

const Filter = ({
  extraFilters = [],
  vertical = false,
  tagFilter = false,
  tags,
  setTags,
  types = [
    { label: "Nº do processo", type: "cvfRef" },
    { label: "Ncm", type: "ncm" },
    { label: "Origem", type: "origin" },
    { label: "Fornecedor", type: "supplier" },
    { label: "Produto", type: "product" },
  ],
}: {
  extraFilters?: any[];
  vertical?: boolean;
  tagFilter?: boolean;
  tags: Tag[];
  setTags: any;
  types?: { label: string; type: string }[];
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  return (
    <Box w="100%">
      <Heading size="lg" fontWeight="semibold" marginBottom="4px">
        Filtros
      </Heading>
      <Text fontSize="17px" marginBottom="24px">
        Faça o filtro de acordo com as informações que você deseja visualizar
      </Text>
      {tagFilter ? (
        <GeneralTagFilter
          {...{
            types,
            tags,
            setTags,
          }}
        />
      ) : (
        <Box w="100%">
          <Flex
            flexDirection={isMobile || vertical ? "column" : "row"}
            gap={vertical ? 0 : "32px"}
            w="100%"
            wrap="wrap"
          >
            {types.map((t) => (
              <TagFilter
                {...{
                  tags,
                  setTags,
                }}
                label={t.label}
                type={t.type}
              />
            ))}
            {extraFilters.map((extraFilter) => extraFilter)}
          </Flex>
          {tags.length > 0 && (
            <Button
              backgroundColor="var(--icon-color)"
              color="white"
              mb="24px"
              onClick={() => setTags([])}
            >
              Limpar filtros
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Filter;
