import { Box, Flex, Heading, ResponsiveValue, Text } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const Card = ({
  option,
}: {
  option: {
    color: string;
    title: string;
    icon: any;
    link: string;
    position?: ResponsiveValue<any>;
  };
}) => {
  const navigate = useNavigate();

  return (
    <Flex
      flexDirection="column"
      cursor="pointer"
      width="288px"
      height="197px"
      overflow="hidden"
      justifyContent="center"
      backgroundColor="white"
      alignItems="center"
      borderRadius="6px"
      color="var(--icon-color)"
      _hover={{
        bottom: "5px",
        backgroundColor: "var(--icon-color)",
        color: "white",
      }}
      onClick={() => navigate(option.link)}
    >
      <Box fontSize="40px" className="material-symbols-outlined">
        {option.icon}
      </Box>
      <Text
        marginTop="16px"
        textAlign="center"
        fontSize="29px"
        letterSpacing="0px"
      >
        {option.title}
      </Text>
    </Flex>
  );
};

export default Card;
