import {
  Flex,
  IconButton,
  Image,
  Link,
  useDisclosure,
  Drawer,
  DrawerContent,
  Box,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";
import { options } from "../../models/Home";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMediaQuery } from "react-responsive";
import HeaderOption from "./HeaderOption";
import HeaderMenu from "./HeaderMenu";
import HeaderMenuContent from "./HeaderMenuContent";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const Header = ({ isHome = false }: { isHome?: boolean }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const auth = useAuthUser<any>();

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Flex padding="16px" height={{ base: "70px", md: "60px" }}>
      {isMobile ? (
        <Flex justifyContent="space-between" w="100%">
          <Link href="/" mb="32px">
            <Image src="logo.png" height="40px" marginRight="100px" />
          </Link>
          <Drawer
            autoFocus={false}
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            returnFocusOnClose={false}
            onOverlayClick={onClose}
            size="full"
          >
            <DrawerContent
              borderBottomWidth="1px"
              borderBottomColor="whitesmoke"
            >
              <Flex justifyContent="space-between" padding="16px">
                <Link href="/" mb="32px">
                  <Image src="logo.png" height="40px" marginRight="100px" />
                </Link>
                <FontAwesomeIcon
                  onClick={onClose}
                  fontSize="32px"
                  icon={faClose}
                />
              </Flex>
              <Flex alignItems="center" flexDirection="column">
                {options.map((o) => (
                  <HeaderOption
                    key={o.title}
                    style={{
                      height: "60px",
                      width: "100%",
                      borderBottomWidth: "1px",
                      borderBottomColor: "whitesmoke",
                    }}
                    o={o}
                  />
                ))}
                <Box
                  width="90%"
                  marginY="32px"
                  borderTopColor="gray"
                  borderTopWidth="1px"
                ></Box>
                <HeaderMenuContent gap="8px" />
              </Flex>
            </DrawerContent>
          </Drawer>

          <IconButton
            variant="outline"
            onClick={onOpen}
            aria-label="open menu"
            icon={<FontAwesomeIcon style={{ top: "3px" }} icon={faBars} />}
          />
        </Flex>
      ) : (
        <>
          <Flex w="100%" justifyContent="space-between" alignItems="center">
            <Link href="/">
              <Image src="logo.png" height="40px" marginRight="100px" />
            </Link>
            {!isHome && (
              <Flex w="100%" justifyContent="center" alignItems="center">
                {options.map((o) => (
                  <HeaderOption key={o.title} o={o} />
                ))}
              </Flex>
            )}
          </Flex>
          <Flex gap="64px">
            <HeaderMenu />
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default Header;
