import {
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  Table,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Flag from "react-world-flags";
import { GraphViewModel } from "../../../models/Dashboard";
import ShowMoreModal from "./ShowMoreModal";
import GraphModal from "./GraphModal";

const DataList = ({
  loading,
  isPrinting,
  graph,
  graphType,
  formatter,
  title,
  button,
}: {
  loading: boolean;
  isPrinting: boolean;
  graph: GraphViewModel | undefined;
  graphType: string;
  formatter: Intl.NumberFormat;
  title: string;
  button: string;
}) => {
  const {
    onOpen: onOpenShowAll,
    isOpen: isOpenShowAll,
    onClose: onCloseShowAll,
  } = useDisclosure();
  const {
    onOpen: onOpenGraph,
    isOpen: isOpenGraph,
    onClose: onCloseGraph,
  } = useDisclosure();
  return (
    <>
      {loading ? (
        <Flex
          justifyContent="center"
          alignItems="center"
          className="dashboard-card"
          minH="600px"
          width={{ base: "100%", md: "50%" }}
        >
          <Spinner size="xl" />
        </Flex>
      ) : (
        <Flex
          justifyContent="space-between"
          className="dashboard-card"
          width={{ base: "100%", md: "50%" }}
        >
          <Box mt="24px">
            <Heading size="md" mb="48px" fontWeight="semibold">
              {title}
            </Heading>
            <Flex flexDir="column" gap="24px">
              {(isPrinting ? graph?.values : graph?.values.slice(0, 10))?.map(
                (v) => (
                  <Flex justifyContent="space-between">
                    <Flex gap="24px">
                      {!isPrinting && <Flag code={v.code} width="42px" />}
                      <Heading size="sm" fontWeight="normal">
                        {v.label}
                      </Heading>
                    </Flex>
                    <Heading size="sm">
                      {graphType === "1"
                        ? formatter.format(v.usd)
                        : v.numberOfProcesses}
                    </Heading>
                  </Flex>
                )
              )}
            </Flex>
          </Box>
          {!isPrinting && (
            <Flex flexDir="column" w="100%" gap="12px" mt="24px">
              <Button
                onClick={onOpenShowAll}
                backgroundColor="var(--icon-color)"
                color="white"
              >
                VISUALIZAR {button}
              </Button>

              <Button
                onClick={onOpenGraph}
                backgroundColor="white"
                borderColor="var(--icon-color)"
                borderWidth="1px"
                color="var(--icon-color)"
              >
                GERAR GRÁFICO
              </Button>
            </Flex>
          )}
          <ShowMoreModal
            values={graph?.values ?? []}
            title={button}
            isOpen={isOpenShowAll}
            onClose={onCloseShowAll}
            {...{ formatter, graphType }}
          />
          <GraphModal
            isOpen={isOpenGraph}
            onClose={onCloseGraph}
            {...{ graph, formatter, graphType }}
          />
        </Flex>
      )}
    </>
  );
};

export default DataList;
