import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";

const HeaderOption = ({ o, style }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const activeLink =
    location.pathname.includes(o.link) ||
    (location.pathname.includes("process") && o.link.includes("process"));
  return (
    <Flex
      height="100%"
      onClick={() => navigate(o.link)}
      backgroundColor={activeLink ? "var(--main-bg-color)" : undefined}
      borderBottomWidth={activeLink ? "3px" : undefined}
      borderBottomColor={activeLink ? "var(--icon-color)" : undefined}
      _hover={{
        backgroundColor: activeLink ? "var(--main-bg-color)" : "var(--hover-color)",
      }}
      cursor="pointer"
      padding="12px 32px"
      style={{ ...style }}
      alignItems="center"
      gap="8px"
      fontWeight="semibold"
    >
      <Box
        fontWeight="semibold"
        color="var(--icon-color)"
        fontSize="16px"
        className="material-symbols-outlined"
      >
        {o.icon}
      </Box>
      <Text
        color={activeLink ? "var(--icon-color)" : undefined}
        textAlign="center"
        fontSize="17px"
        letterSpacing="0px"
      >
        {o.title}
      </Text>
    </Flex>
  );
};
export default HeaderOption;
