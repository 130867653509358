import { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMediaQuery } from "react-responsive";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import {
  deletePermission,
  getPermissions,
  savePermission,
} from "../../../services/permissionService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { FaKey } from "react-icons/fa";

const Permissions = () => {
  const { register, handleSubmit, reset } = useForm();
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState<any[]>([]);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const getAllPermissions = async () => {
    const response = await getPermissions();
    if (response) {
      setPermissions(response);
    }
    setLoading(false);
  };

  const remove = async (permissionId: string) => {
    setLoading(true);
    try {
      const response = await deletePermission(permissionId);
      if (response) {
        toast({
          title: "Permissão excluída.",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
        await getAllPermissions();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllPermissions();
  }, []);

  const save = async (data: any) => {
    setLoading(true);
    try {
      const response = await savePermission(data.name);

      if (response) {
        toast({
          title: "Permissão criada.",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
        reset();
        await getAllPermissions();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box width="100%">
      <Heading fontWeight="norma" size="lg">
        <Flex alignItems="center" gap="12px">
          <FaKey color="var(--icon-color)" fontSize={isMobile ? 18 : 24} />{" "}
          Gestão de permissões
        </Flex>
      </Heading>
      <form onSubmit={handleSubmit(save)}>
        <Box
          backgroundColor="white"
          p="24px"
          w="100%"
          mt="24px"
          borderRadius="8px"
        >
          <Heading size="sm" fontWeight="normal" mb="8px">
            Nome da permissão
          </Heading>
          <Input
            {...register("name", {
              required: "Esse campo é obrigatório.",
            })}
          />
        </Box>
        <Flex w="100%" justifyContent="flex-end">
          <Button
            type="submit"
            marginTop="16px"
            isLoading={loading}
            backgroundColor="var(--icon-color)"
            color="white"
          >
            Salvar
          </Button>
        </Flex>
      </form>
      <Flex
        flexDir="column"
        gap="16px"
        marginTop="32px"
        minH="300px"
        backgroundColor="white"
        borderRadius="8px"
        p="24px"
      >
        <Heading size="md" fontWeight="normal">
          Permissões salvas
        </Heading>
        {permissions.map((p) => (
          <Flex alignItems="center" gap="8px">
            <Heading size="sm" fontWeight="normal">
              {p.name}
            </Heading>
            <Popover placement="top">
              <PopoverTrigger>
                <FontAwesomeIcon cursor="pointer" icon={faTrash} />
              </PopoverTrigger>
              <PopoverContent>
                <PopoverHeader fontWeight="semibold">Confirmação</PopoverHeader>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>Tem certeza que deseja excluir?</PopoverBody>
                <PopoverFooter display="flex" justifyContent="flex-end">
                  <ButtonGroup size="sm">
                    <Button
                      onClick={() => {
                        remove(p.id);
                      }}
                      isLoading={loading}
                      colorScheme="red"
                      position="relative"
                      aria-label="Deletar"
                    >
                      Excluir
                    </Button>
                  </ButtonGroup>
                </PopoverFooter>
              </PopoverContent>
            </Popover>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
};

export default Permissions;
