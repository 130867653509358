import { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faCheckCircle,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { generateSignUpToken } from "../../../services/userService";
import { useNavigate } from "react-router-dom";
import { getCompanies } from "../../../services/companyService";
import { useMediaQuery } from "react-responsive";
import { FaPlus, FaUserPlus } from "react-icons/fa";

const AddUsers = () => {
  const navigate = useNavigate();
  const [emails, setEmails] = useState<any[]>([]);
  const [company, setCompany] = useState<any>([]);
  const [companies, setCompanies] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<string>();
  const [error, setError] = useState<string>();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const sendEmails = async () => {
    setLoading(true);
    const response = await generateSignUpToken({
      companyId: company,
      email: emails.join(";"),
    });
    if (response) {
      onOpen();
    }
    setLoading(false);
  };
  const getCompaniesOptions = async () => {
    const response = await getCompanies();
    if (response) setCompanies(response);
  };
  useEffect(() => {
    getCompaniesOptions();
  }, []);
  return (
    <Box>
      <Heading size="lg" mt="48px" mb="24px" fontWeight="normal">
        <Flex alignItems="center" gap="12px">
          <FaUserPlus color="var(--icon-color)" /> Adicionar usuários
        </Flex>
      </Heading>
      <Box width="100%" backgroundColor="white" borderRadius="8px" p="24px">
        <Heading size="sm" fontWeight="normal">
          E-mail(s)
        </Heading>
        <Flex flexDirection="column">
          <ButtonGroup>
            <Input value={email} onChange={(e) => setEmail(e.target.value)} />
            <IconButton
              onClick={() => {
                const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                const valid = regexEmail.test(email ?? "");
                if (!valid) {
                  setError("E-mail inválido.");
                  return;
                }
                if (emails.some((e) => e === email)) {
                  setError("E-mail já adicionado.");
                  return;
                }
                setError("");
                setEmail("");
                setEmails((emails: any[]) => [...emails, email]);
              }}
              color="white"
              backgroundColor="var(--icon-color)"
              aria-label="add"
              icon={<FaPlus />}
            />
          </ButtonGroup>
          <Flex flexWrap={"wrap"} marginY="8px" gap="16px">
            {emails.map((e) => (
              <Flex
                borderRadius="12px"
                alignItems="center"
                gap="6px"
                backgroundColor="whitesmoke"
                borderWidth="1px"
                borderColor="lightgray"
                padding="3px 6px"
              >
                <Text fontWeight="semibold">{e}</Text>
                <FontAwesomeIcon
                  icon={faClose}
                  color="#0E2954"
                  onClick={() =>
                    setEmails((emails: any[]) => {
                      const newEmails = emails.map((t) => t);
                      newEmails.splice(
                        emails.findIndex((t) => t === email),
                        1
                      );
                      return newEmails;
                    })
                  }
                />
              </Flex>
            ))}
          </Flex>
          {error && (
            <Text marginTop="16px" color="red">
              {error}
            </Text>
          )}
          <Heading size="sm" fontWeight="normal" mt="24px" marginBottom="8px">
            Empresa
          </Heading>
          <Select value={company} onChange={(e) => setCompany(e.target.value)}>
            {companies.map((c) => (
              <option key={c.id} value={c.id}>
                {c.name}
              </option>
            ))}
          </Select>
        </Flex>
        <Modal size="xl" isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <Flex
                alignItems="center"
                flexDirection="column"
                padding="64px"
                gap="64px"
              >
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="green"
                  fontSize="120px"
                />
                <Heading textAlign="center">
                  E-mail(s) enviado(s) com sucesso!
                </Heading>
                <ButtonGroup>
                  <Button
                    backgroundColor="#29234b"
                    color="white"
                    onClick={() => navigate("/")}
                  >
                    Voltar para o Início
                  </Button>
                  <Button onClick={onClose}>Enviar mais e-mails</Button>
                </ButtonGroup>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
      <Flex w="100%" justifyContent="flex-end">
        <Button
          marginY="32px"
          backgroundColor="var(--icon-color)"
          color="white"
          onClick={() =>
            error?.length === 0 && emails.length > 0 && sendEmails()
          }
          isLoading={loading}
        >
          Enviar e-mails
        </Button>
      </Flex>
    </Box>
  );
};

export default AddUsers;
