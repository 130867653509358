import { DateTime } from "luxon";
import CurrencyAPI from "@everapi/currencyapi-js";
import api from "./api";

export const getCurrency = async () => {
  const response = await api.get("/api/process/currencies");

  const currency = {
    date: DateTime.now().startOf("day"),
    dollar: {
      increased: response.data.dollarBrInc,
      value: response.data.dollarBr.toFixed(2).replace(".", ","),
    },
    euro: {
      increased: response.data.euroBrInc,
      value: response.data.euroBr.toFixed(2).replace(".", ","),
    },
    yuen: {
      increased: response.data.yuenBrInc,
      value: response.data.yuenBr.toFixed(2).replace(".", ","),
    },
  };
  localStorage.setItem("currency", JSON.stringify(currency));

  return currency;
};
