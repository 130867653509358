import { SaveTrackingCommand, UpdateTrackingCommand } from "../models/Tracking";
import api from "./api";

export const getTrackingById = async (id: string) => {
  try {
    const response = await api.get(`/api/tracking/${id}`);
    if (!response.data.success) {
      console.log("error", response.data);
    }
    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const saveTracking = async (tracking: SaveTrackingCommand) => {
  try {
    const response = await api.post(`/api/tracking`, tracking);
    if (!response.data.success) {
      console.log("error", response.data);
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const saveTrackingWithRequest = async (
  tracking: SaveTrackingCommand
) => {
  try {
    const response = await api.post(`/api/tracking/saveWithRequest`, tracking);
    if (!response.data.success) {
      console.log("error", response.data);
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const updateTracking = async (tracking: UpdateTrackingCommand) => {
  try {
    const response = await api.put(`/api/tracking/${tracking.id}`, tracking);
    if (!response.data.success) {
      console.log("error", response.data);
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const removeTracking = async (id: string) => {
  try {
    const response = await api.delete(`/api/tracking/${id}`);
    if (!response.data.success) {
      console.log("error", response.data);
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
};
