import {
  Flex,
  Heading,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCog,
  faDoorOpen,
  faUserCog,
} from "@fortawesome/free-solid-svg-icons";
import HeaderMenuContent from "./HeaderMenuContent";

const HeaderMenu = () => {
  const signOut = useSignOut();
  const navigate = useNavigate();

  const handleSignOut = () => {
    signOut();
    navigate("/login");
  };

  return (
    <Popover>
      <PopoverTrigger>
        <Flex
          alignItems="center"
          justifyContent="center"
          gap="8px"
          cursor="pointer"
        >
          <FontAwesomeIcon style={{ top: "3px" }} icon={faBars} />
          <Heading size="md">Menu</Heading>
        </Flex>
      </PopoverTrigger>
      <PopoverContent width="300px">
        <HeaderMenuContent />
      </PopoverContent>
    </Popover>
  );
};

export default HeaderMenu;
