import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import { AxiosError } from "axios";
import { forgotPassword } from "../../services/userService";
import { useForm } from "react-hook-form";
import { useMediaQuery } from "react-responsive";

function Forgot() {
  const { register, handleSubmit, formState } = useForm();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const isAuth = useIsAuthenticated();

  const onSubmit = async (data: any) => {
    setLoading(true);
    setError("");

    try {
      const response = await forgotPassword(data.email);
      if (response?.data) {
        setSuccess(true);
      } else {
        setError("E-mail ou senha inválidos");
      }
    } catch (err) {
      if (err && err instanceof AxiosError)
        setError("Email ou senha inválidos");
      else if (err && err instanceof Error)
        setError("Email ou senha inválidos");

      console.log("Error: ", err);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isAuth) {
      navigate("/");
    }
  }, []);

  return (
    <Box height="100vh" overflow="hidden" backgroundColor="whitesmoke">
      <Flex
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Box>
          <Flex
            position="relative"
            width={isMobile ? "45vh" : "60vh"}
            backgroundColor="white"
            padding="64px"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            boxShadow="3px 3px 12px -3px rgba(0,0,0,0.06)"
          >
            {success ? (
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gap="32px"
              >
                <FontAwesomeIcon
                  color="green"
                  icon={faCheckCircle}
                  fontSize="64px"
                />
                <Heading size="lg" textAlign="center">
                  E-mail enviado com sucesso!{" "}
                </Heading>
                <Link
                  style={{ fontSize: "24px", textDecoration: "underline" }}
                  to="/login"
                >
                  Voltar para a tela de login
                </Link>
              </Flex>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                <Heading>Recuperação de senha</Heading>
                <FormControl marginTop="64px" marginBottom="32px">
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<FontAwesomeIcon icon={faEnvelope} />}
                      color="gray.300"
                    />
                    <Input
                      {...register("email", {
                        required: "E-mail é obrigatório.",
                      })}
                      type="email"
                      placeholder="E-mail"
                    />
                  </InputGroup>
                  {(formState.errors.email || error) && (
                    <Text color="red" marginTop="16px">
                      {error ??
                        (typeof formState.errors.email?.message === "string" &&
                          formState.errors.email?.message)}
                    </Text>
                  )}
                </FormControl>
                <Button
                  marginY="32px"
                  borderRadius="3px"
                  type="submit"
                  variant="solid"
                  backgroundColor="#29234b"
                  color="white"
                  width="full"
                  isLoading={loading}
                >
                  Enviar e-mail de recuperação
                </Button>
              </form>
            )}
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}
export default Forgot;
